import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { override: Boolean }

  connect() {
    this.getFromValue()
  }

  getFromValue(event = null) {
    const taskTypeValue = document.querySelector("#list_items_task_type").value;
    if (taskTypeValue !== "email") return;
    event?.preventDefault();

    const commTemplateSelectField = document.getElementById("list_items_communication_detail_communication_template_id");
    let templateId = commTemplateSelectField[commTemplateSelectField.selectedIndex]?.value;
    const fromField = document.getElementById("task_from_input");

    if (templateId != undefined && (fromField.value == "" || this.overrideValue)) {
      fetch(`/get_communication_from_value?template_id=${templateId}`)
        .then(response => response.json())
        .then((data) => {
  
          if (data.from_value === null) {
            return;
          }
  
          fromField.value = data.from_value;
        })
        .catch(error => console.log(error));
    } 

  }
}