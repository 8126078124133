export function validateForm(formSelector) {  
  let formIsValid = true;
  const form = document.querySelector(formSelector);
  const requiredInputs = form.querySelectorAll('input[required], select[required], textarea[required]');

  requiredInputs.forEach((el) => { 
    if (!el.required || el.validity.valid) {
      el.classList.remove('has-errors');
      return
    };
    

    formIsValid = false;
    addErrors(el);
  });

  return formIsValid;
}

export function addErrors(el) {
  let targetEl = el.classList.contains('hidden-multiselect') ? el.parentElement : el;
  targetEl.classList.add('has-errors');

  let possibleErrorMessage = targetEl.nextElementSibling;
  let isErrorMessage = possibleErrorMessage?.classList?.contains("input-error-message");
  if (!isErrorMessage) targetEl.insertAdjacentHTML('afterend', `<span class="input-error-message">${el.validationMessage}</span>`);

  el.addEventListener('focus', (e) => removeError(e, targetEl));
  el.addEventListener('keydown', (e) => removeError(e, targetEl));
}

function removeError(e, targetEl) {
  targetEl.classList.remove('has-errors');
  const errorMessage = targetEl.nextElementSibling;
  if (errorMessage?.classList?.contains("input-error-message")) errorMessage.remove();
}