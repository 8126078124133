import ApplicationController from "./application_controller";
import { setAccordionHeight } from "../../components/design/accordion/accordion_controller";
export default class extends ApplicationController {
  static targets = [ "taskDetails" ]

  toggleTaskDetails() {
    if (this.taskDetailsTarget.style.maxHeight) {
      this.taskDetailsTarget.style.maxHeight = null;
      setTimeout(() => {
        this.element.classList.remove("is-open");
      }, 250)
    } else {
      this.open();
    }
  }
  open() {
    this.element.classList.add("is-open");
    setAccordionHeight(this.taskDetailsTarget);
  }
}