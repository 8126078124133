import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  applyDefault(e) {
    e.preventDefault();
    const form = document.querySelector("#default-task-form");
    form.requestSubmit();
  }
}
