import ApplicationController from "./application_controller";
import { removeSelectedCard, toggleSelectedPanel, updateContentHeight } from "./selected_panel_controller";

export default class extends ApplicationController {
  static targets = [ "templateCardEl" ]
  static values = { templateId: String, resourceGid: String }

  initialize() {
    this.selectedPanel = document.getElementById("selected-panel");
  }

  selectTemplate(e) {
    const templateCard = e.currentTarget;
    const isSelected = templateCard.classList.contains("is-selected");
    this.selectedPanel.dataset.selectedTemplate = this.templateIdValue;
  
    if (isSelected) {
      removeSelectedCard();
      toggleSelectedPanel();
    } else {
      this.selectedPanel.dataset.isOpen == "true" ? removeSelectedCard() : toggleSelectedPanel();
      templateCard.classList.toggle("is-selected");
      setTimeout(() => {
        templateCard.scrollIntoView({behavior: "smooth"});
      }, 350);
    }
    this.fetchAndUpdateTemplate(isSelected);
  }
  
  fetchAndUpdateTemplate(isSelected) {
    if (!isSelected) {
      fetch(`/select_communication_template?communication_template_id=${this.templateIdValue}&resource_gid=${this.resourceGidValue}`)
        .then(response => response.json())
        .then(data => this.updatePanel(data))
        .catch(error => console.log(error));
    }
  }
  
  updatePanel(data) {
    let panelContainer = document.querySelector("#selected-panel .selected-panel--container");
    panelContainer.innerHTML = data.html;
    updateContentHeight(this.selectedPanel);
  }
  
}