import ApplicationController from "./application_controller";
import consumer from "../channels/consumer";
import DOMPurify from "dompurify";

export default class extends ApplicationController {
  static targets = ["messages"];

  connect() {
    super.connect();

    const channel = this.data.get("channel");

    this.subscription = consumer.subscriptions.create(
      { channel: channel, resource_sgid: this.data.get("sgid") },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.message && this.hasMessagesTarget) {
      this.messagesTarget.insertAdjacentHTML("afterbegin", DOMPurify.sanitize(data.message));
      const activity = this.messagesTarget.firstElementChild; 
      activity.classList.add("animate", "animate-zoom-in");

      if (this.messagesTarget.querySelector("h4")) this.messagesTarget.querySelector("h4")?.remove();
      super.renderToast(
        "#activity-container",
        `${DOMPurify.sanitize(data.change_msg)}. Please refresh the page.`,
        '',
        true,
        data.current_user_id, 
        30000
      );
    }
  }
}
