import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    super.connect();
    const activeTab = document.querySelector('[data-is-active="true"]');
    const type = activeTab.dataset.btnTypeValue;
    const resourceEnabled = activeTab.dataset.resourceEnabled;
    const tasksEnabled = activeTab.dataset.tasksEnabled;

    if (type === 'tasks' ||  ( resourceEnabled == 'false' && tasksEnabled == 'true' ) ) {
      const moduleType = activeTab.dataset.moduleType;
      const srcValue = "/tasks?module_type=" + moduleType;
      document.getElementById('resources-index--container--page-bottom-view-frame').setAttribute('src', srcValue);
    }
  }

}