import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [ "backBtn", "nextBtn" ]

  initialize() {
    this.tabsContainerEl = document.querySelector(".module-manager-tabs-container");
    this.currentTab = this.tabsContainerEl.querySelector(".tab[data-is-active='true']");
    this.currentPage = this.currentTab.dataset.tabNumber;
    this.nextPageNumber = Number(this.currentPage) + 1;
    this.previousPageNumber = Number(this.currentPage) - 1;
    this.setNextBtn();
    this.setBackBtn();
  }
  
  setBackBtn() {
    if (this.previousPageNumber === 0) {
      this.backBtnTarget.classList.add("disabled");
      return;
    }
    this.previousTab = this.tabsContainerEl.querySelector(`[data-tab-number="${this.previousPageNumber}"]`);
    this.previousTabURL = this.previousTab.href;
    this.backBtnTarget.addEventListener("click", (e) => {
      window.location = this.previousTabURL;
    });
  }

  setNextBtn() {
    if (this.nextPageNumber === 7) {
      this.nextBtnTarget.classList.add("disabled");
      return;
    }
    this.nextTab = this.tabsContainerEl.querySelector(`[data-tab-number="${this.nextPageNumber}"]`);
    this.nextTabURL = this.nextTab.href;
    this.nextBtnTarget.addEventListener("click", (e) => {
      window.location = this.nextTabURL;
    })
  }
}