import ApplicationController from "../../../javascript/controllers/application_controller";
export default class extends ApplicationController {
  static targets = ["defaultBtn"]

  switchTab(e) {
    this.removeActiveClasses(this.element);
    this.addIsActive(e.target);
  }

  addIsActive(clickedTab) {
    clickedTab.dataset['isActive'] = "true"; 
    clickedTab.setAttribute("tabindex", "-1");
    clickedTab.setAttribute("aria-selected", "true");
  }

  removeActiveClasses(tabContainerParent) {
    const activeTab = tabContainerParent.querySelector('[data-is-active="true"]');
    activeTab.dataset["isActive"] = "false";
    activeTab.removeAttribute("tabindex");
    activeTab.setAttribute("aria-selected", "false");
  }

  defaultBtnClick(e) {
    const clickedDefaultBtn = e.currentTarget;
    this.defaultBtnTargets.forEach((defaultBtn) => {
      if (defaultBtn === clickedDefaultBtn) return;
      defaultBtn.classList.remove("is-default")
      defaultBtn.classList.remove("animate-flip")
      const tab = this.element.querySelector(`#${defaultBtn.dataset.defaultView} .tab`);
      tab.dataset.isActive = false;
    });
    const clickedTab = this.element.querySelector(`#${clickedDefaultBtn.dataset.defaultView} .tab`);
    clickedDefaultBtn.classList.add("is-default");
    clickedDefaultBtn.classList.add("animate-flip");
    clickedTab.dataset.isActive = true;
  }
}