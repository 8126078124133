import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "selectInput", 
    "selectDropdown", 
    "visibleSelection", 
    "placeholder", 
    "selectOption", 
    "pillTemplate", 
    "selectedPill",
    "noMatchMsg",
    "searchInput",
    "selectAllBtn",
    "searchOnlyMsg"
  ]
  static values = { 
    hasSearch: Boolean, 
    hasSelectAll: Boolean, 
    isOpen: Boolean,
    multiple: Boolean,
    disabledCollection: Array,
    isSingle: Boolean,
    searchOnly: Boolean,
  }

  initialize() {
    // Render select box pills if selected (like on edit)
    if (this.hasAnySelections()) this.renderExistingSelectPills();
    this.hasSearchValue = this.hasSearchInputTarget;
    if (this.disabledCollectionValue?.length) this.disableOptions();
    if (this.element?.parentElement) this.element.parentElement.classList.remove("has-focus"); // Fix for re-rendering component whose parent might still have focus, like on filters
  }
  
  toggleDropdownOptions() {
    if (this.selectInputTarget.disabled) return;
    if (this.isOpenValue) return this.hide(); 
    this.show();
    this.setCloseListener();
  }

  show() {
    this.selectDropdownTarget.classList.remove("animate-slide-and-fade-out-up-lesser", "vanish");
    this.selectDropdownTarget.classList.add("animate-slide-and-fade-in-down-lesser");
    this.element.parentElement.classList.add("has-focus");
    this.isOpenValue = true;
    if (this.hasSearchValue) this.searchInputTarget.focus();
    this.selectDropdownTarget.addEventListener("animationend", (e) => {
      if (e.animationName == "animateSlideAndFadeInDownLesser") {
        const dropdownRect = this.selectDropdownTarget.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        if (dropdownRect.bottom > viewportHeight) {
          this.selectDropdownTarget.scrollIntoView({block: "nearest", behavior: "smooth"});
        }
      }
    });
  }

  hide() {
    this.isOpenValue = false;
    this.selectDropdownTarget.classList.add("vanish");
    this.element?.parentElement?.classList?.remove("has-focus");
  }

  selectAll() {
    this.selectAllBtnTarget.dataset.selected == "true" ? this.deselectAllOptions() : this.selectAllOptions();
  }
  
  selectAllOptions() {
    this.selectOptionTargets.forEach(option => {
      if (option.dataset.selected == "true") return;
      option.dataset.selected = "true";
      this.selectedOption = this.selectInputTarget.querySelector(`option[value="${option.dataset.value}"]`);
      this.selectedOption.selected = true;
      this.renderSelectPill(option.nextElementSibling);
    });

    if (this.hasSelectAllBtnTarget) {
      this.selectAllBtnTarget.dataset.selectedType = "select_all";
      this.enableDeselectAllButton();
    }

    this.removeOrReplacePlaceholder();
  }

  deselectAllOptions() {
    if (!this.hasAnySelections()) return;
    this.selectOptionTargets.forEach(option => {
      if (option.dataset.selected == "false") return;
      option.dataset.selected = "false";
      this.selectedOption = this.selectInputTarget.querySelector(`option[value="${option.dataset.value}"]`);
      this.selectedOption.selected = false;
      this.removeSelectedPill(option.dataset.value, null);
    });

    if (this.hasSelectAllBtnTarget) {
      this.selectAllBtnTarget.dataset.selectedType = "deselect_all";

      this.enableSelectAllButton()
    }
    super.removeVanish(this.placeholderTarget);
  }

  // Close select dropdown when user clicks out
  setCloseListener() {
    const closeListener = (e) => {
      if (!this.element.contains(e.target)) {
        this.selectDropdownTarget.classList.remove("animate-slide-and-fade-in-down-lesser");
        this.selectDropdownTarget.classList.add("animate-slide-and-fade-out-up-lesser");
        setTimeout(() => {
          this.hide();
        }, 500);
        document.removeEventListener("click", closeListener);
      }
    };
  
    document.addEventListener("click", closeListener);
  }

  // If there's a selection and .has-errors has been added by checkMultiselectsValidity(), removes it.
  removeErrors() {
    if (this.hasAnySelections()) this.element.classList.remove("has-errors");
  }

  // Value inside dropdown clicked
  selectValue(e) {    
    this.selection = e.currentTarget;
    const selectedPill = this.selection.nextElementSibling; // Grabs hidden div that is pill so it can be appended to visible select box
    this.clickedOptionValue = this.selection.dataset.value;
    this.selectedOption = this.selectInputTarget.querySelector(`option[value="${this.clickedOptionValue}"]`);
    const filterPill = document.querySelector(`#${this.selection.dataset.filterId}-filter-pill`);
    const hiddenMultiselectOption = document.querySelector(`#hidden-multiselect-${this.selection.dataset.filterId}`)

    if (this.isSingleValue && this.selection.dataset.selected == "true") {
      this.removeSelectedPill(this.clickedOptionValue, null);

      if (hiddenMultiselectOption && filterPill) {
        hiddenMultiselectOption.setAttribute("data-remove-pill", "true");
        filterPill?.classList?.add("vanish");
      }

      this.selection.dataset.selected = "false";
      this.selectedOption.selected = false;
      this.selectInputTarget.value = "";
      this.removeErrors();
      this.removeOrReplacePlaceholder();
      this.selectInputTarget.dispatchEvent(new Event("change", { bubbles: true }));
      return;
    } else if (this.isSingleValue && hiddenMultiselectOption) {
      hiddenMultiselectOption.removeAttribute("data-remove-pill");
    }
    
    if (this.isSingleValue) {
      // send a change event to input to trigger the change event on the input like reflexes
      this.selectOptionTargets.forEach(option => {
        if (option.dataset.value == this.clickedOptionValue) return;
        option.removeAttribute("data-selected");
      })
      this.selectedPillTargets.forEach(pill => {
        pill.remove();
      });
      this.toggleDropdownOptions();
    }
    
    if (this.selection.dataset.selected == "true") {
      this.selection.dataset.selected = "false";
      this.selectedOption.selected = false;
      if (!this.isSingleValue) this.removeSelectedPill(this.clickedOptionValue, null);
    } else {
      this.selection.dataset.selected = "true";
      this.selectedOption.selected = true;
      this.renderSelectPill(selectedPill);
    }

    if (this.selectInputTarget.selectedOptions.length == this.selectOptionTargets.length && this.hasSelectAllBtnTarget) {
      this.enableDeselectAllButton();
    } else if (this.selectInputTarget.selectedOptions.length == 0 && this.hasSelectAllBtnTarget) {
      this.enableSelectAllButton();
    }
    
    this.selectInputTarget.dispatchEvent(new Event("change", { bubbles: true }));
    this.removeErrors();
    this.removeOrReplacePlaceholder();
  }
  
  // pill inside of select box clicked
  removeValue(e) {
    e.stopPropagation();
    const selectedPill = e.currentTarget;
    this.clickedOptionValue = selectedPill.dataset.value;
    this.selectedOption = this.selectInputTarget.querySelector(`option[value="${this.clickedOptionValue}"]`);
    this.selectedOption.selected = false;

    this.selectOptionTargets.forEach(option => { 
      if (option.dataset.value == this.clickedOptionValue) option.dataset.selected = "false";
    })

    this.removeSelectedPill(this.clickedOptionValue, selectedPill)

    if (this.selectInputTarget.selectedOptions.length == 0) { this.enableSelectAllButton() }

    this.selectInputTarget.dispatchEvent(new Event("change", { bubbles: true }));
    this.removeOrReplacePlaceholder();
  }

  removeSelectedPill(value, selectedPill) {
    const pill = selectedPill || this.selectedPillTargets.find(pill => pill.dataset.value == value);
    pill.classList.remove("animate-fade-in");
    pill.classList.add("animate-fade-out");
    setTimeout(() => {
      pill.remove();
    }, 450);
  }

  renderSelectPill(template) {
    this.pill = template.cloneNode(true)
    this.pill.id = this.pill.id.replace(/\-template$/, "")

    this.pill.classList.remove('vanish', 'multiselect-dropdown-pill-template');
    this.pill.classList.add('multiselect-dropdown-pill');
    this.pill.setAttribute('data-forms--multiselect--multiselect-component-target', 'selectedPill');
    this.visibleSelectionTarget.append(this.pill);
  }

  renderExistingSelectPills() {
    this.selectOptionTargets.forEach((option) => {
      if (option.dataset.selected == "true") this.renderSelectPill(option.nextElementSibling);
    })
  }

  removeOrReplacePlaceholder() {
    if (this.hasAnySelections() && this.selectInputTarget.value != "") return super.addVanish(this.placeholderTarget);
    setTimeout(() => {
      super.removeVanish(this.placeholderTarget);
    }, 450) // Delay so animation can finish
  }

  hasAnySelections() {
    return this.selectInputTarget.selectedOptions.length > 0;
  }

  search(e) {
    const searchValue = e.target.value.toUpperCase();
    let optionValue;
    let hasMatch = false;
    let searchIsEmpty = searchValue == "";

    this.selectOptionTargets.forEach((option) => {
      optionValue = option.textContent || option.innerText;
      if (optionValue.toUpperCase().indexOf(searchValue) > -1) {
        option.style.display = "";
        if (this.searchOnlyValue && searchValue != "") option.style.display = "flex";
        hasMatch = true;
        this.toggleCategories(true);
      } else {
        option.style.display = "none";
      }
    });

    if (searchIsEmpty) {
      hasMatch = false;
      this.toggleCategories(false);
      if (this.searchOnlyValue) super.removeVanish(this.searchOnlyMsgTarget);
    }

    if (hasMatch) {
      super.addVanish(this.noMatchMsgTarget);
      if (this.searchOnlyValue && searchValue != "") super.addVanish(this.searchOnlyMsgTarget);
      return;
    }
    
    if (this.searchOnlyValue && searchIsEmpty) return;
    super.removeVanish(this.noMatchMsgTarget);
    if (!this.searchOnlyValue && searchIsEmpty && !hasMatch) super.addVanish(this.noMatchMsgTarget);
  }
  
  // Hide any category names
  toggleCategories(hide) {
    document?.querySelectorAll(".multiselect-category")?.forEach((categoryText) => {
      if (hide) {
        return super.addVanish(categoryText);
      }
      super.removeVanish(categoryText);
    })
  }

  disableOptions() {
    this.disabledCollectionValue.forEach((disabledValue) => {
      const pillOption = this.selectedPillTargets.find(pillOption => pillOption.dataset.value == disabledValue);
      const dropdownOption = this.selectOptionTargets.find(dropdownOption => dropdownOption.dataset.value == disabledValue);
      if (pillOption) pillOption.disabled = true;
      if (dropdownOption) dropdownOption.disabled = true;
    });
  }

  enableDeselectAllButton() {
    if (!this.hasSelectAllBtnTarget) return;
    this.selectAllBtnTarget.dataset.selected = "true";
    this.selectAllBtnTarget.textContent = "Deselect All";
    this.selectAllBtnTarget.title = "Deselect all options below"
  }
  
  enableSelectAllButton() {
    if (!this.hasSelectAllBtnTarget) return;
    this.selectAllBtnTarget.dataset.selected = "false";
    this.selectAllBtnTarget.textContent = "Select All";
    this.selectAllBtnTarget.title = "Select all options below"
  }
}
// Import this into the form's controller if it has required multiselects or use application_controller#validateFormFromSubmitBtn()
// Please provide a more accurate formSelector if possible
// Generate errors for required selects using multiselect-component
export function checkMultiselectsValidity(formSelector = "form") {
  const form = document.querySelector(formSelector);
  const multiselectComponents = form.querySelectorAll('.multiselect-component');
  let isValid = true;
  multiselectComponents.forEach(component => {
    const select = component.querySelector('select');
    if (select.hasAttribute('required') && !select.value) {
      component.classList.add("has-errors");
      isValid = false;
    }
  })
  return isValid;
}