import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { checkboxInputName: String }

  reverseInputCheckedState() {
    const inputCheckbox = document.querySelector(`input[type="checkbox"][name="${this.checkboxInputNameValue}[archive]"]`);
    inputCheckbox.checked = !inputCheckbox.checked;
    inputCheckbox.setAttribute("data-modal--confirm-modal--confirm-modal-is-modal-trigger-value", true)
  }
}