import ApplicationController from "../../../../javascript/controllers/application_controller";
import { closeModal } from "../modal_controller";
import { validateForm } from "../../../../javascript/utils/form_validator";

export default class extends ApplicationController {
  static targets = [
    "confirmBtn"
  ];

  static values = {
    confirmReflexClass: String,
    confirmReflexAction: String,
    selectorId: String,
    isModalTrigger: Boolean,
    icon: String,
    message: String,
    modalTitle: String,
    classes: String,
    toggleMode: Boolean,
    isHtmlMessage: Boolean,
    validateForm: Boolean,
    formSelector: String,
    renderModalOnPageLoad: Boolean,
    confirmReflexActionOptions: Object
  };

  initialize() {
    //? This code executes when the modal is rendered via render_confirm_modal reflex and this.element is modal itself 
    if (!this.hasIsModalTriggerValue) {
      const btnContainer = document.querySelector(`${this.element.dataset['modal-ModalSelectorValue']}-btn`);

      if (btnContainer != undefined) {
        const btn = btnContainer.querySelector("button") || btnContainer.querySelector("a")

        super.removeVanish(btnContainer);
        this.element.querySelector("footer .confirm-modal-button-container").appendChild(btnContainer);

        if (btn != undefined) {
          //? Make sure confirm modal closes when action is clicked
          btn.addEventListener("click", () => closeModal(this.element));
        }
      }
    }
  }

  renderModal(e) {
    // Check form by using form_selector_value to check validity and render erorrs to stop rendering of modal
    if (this.validateFormValue && this.formSelectorValue) {
      if (!this.validateFormValue) return;
      const isValidForm = validateForm(this.formSelectorValue);
      if (!isValidForm) {
        return super.renderToast(
          "body", "Please address all the fields in red.", "danger", false, null, 4500, true
        );
      }
    }
    if (this?.isModalTriggerValue == false) {
      // If toggle Mode is on, toggles state of the elment that triggered the modal so it won't on next click
      this.updateTriggerForToggleMode();
      return;
    }

    const existingModal = document.getElementById(`${this.selectorIdValue}`);
    // Modal is already rendered on page so open it
    if (existingModal) {
      this.updateTriggerForToggleMode();
      document.body.classList.add("modal-is-open");
      if (existingModal.dataset["modal-ModalStopScrollValue"] == "true") document.body.classList.add("stop-scroll");
      return existingModal.classList.add('is-active');
    }

    // Modal is not rendered on page so render it
    this.stimulate(`${this.confirmReflexClassValue}#${this.confirmReflexActionValue}`, this.selectorIdValue, this.iconValue, this.messageValue, this.modalTitleValue, this.classesValue, this.isHtmlMessageValue, this.confirmReflexActionOptionsValue).then(() => {
      this.updateTriggerForToggleMode();
    })
  }

  updateTriggerForToggleMode() {
    if (this?.toggleModeValue) this.isModalTriggerValue = !this.isModalTriggerValue;
  }
}