import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggleModuleNotesTextEditor() {
    const checkbox = this.element;
    const container = checkbox.closest('.module-release-note-input-container');
    const destroyField = container.querySelector('.destroy-field');
    const textEditor = container.querySelector(`${checkbox.dataset.editorSelectorId}`);

    if (checkbox.checked) {
      destroyField.value = "0"; // Ensure the item is not marked for destruction
      textEditor.classList.remove('vanish');
    } else {
      destroyField.value = "1"; // Mark the item for destruction
      textEditor.classList.add('vanish');
    }
  }
}
