import ApplicationController from "./application_controller";
import DOMPurify from "dompurify";

export default class extends ApplicationController {
  static values = {
    licenseNumber: String,
    licenseId: Number
  }

  onSubmitSuccess() {
    // show success toast
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
  }

  confirmLicenseRemove() {
    return this.stimulate("UsersReflex#remove_license", this.licenseIdValue);
  }
}
