import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  sortTable(e) {
    e.preventDefault();
    let table, rows, switching, i, x, y, shouldSwitch;
    const sourceType = e.target.dataset.sourceType;

    table = document.querySelector(`#data-fields-list-${sourceType}`);
    const tbody = table.querySelector(`#data-field-value-${sourceType}`);
    const sortDir = e.target.dataset.sort;

    switching = true;
    while (switching) {
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < (rows.length - 1); i++) {
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].querySelector(".tc-name-input").value;
        y = rows[i + 1].querySelector(".tc-name-input").value;
        // Check if the two rows should switch place:
        if (this.sortEl(x, y, sortDir)) {
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        tbody.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

  sortEl(x,y,sortDir) {
    if (sortDir === "asc") {
      return x.toLowerCase() > y.toLowerCase();
    } else {
      return x.toLowerCase() < y.toLowerCase();
    }
  }
}