export function fetchModal(e, url) {
  const main = document.querySelector("main");
  fetch(url, { redirect: "error" })
    .then((r) => r.text())
    .then((html) => {
      let fragment = document.createRange().createContextualFragment(html);
      main.appendChild(fragment);
      // TODO: This code can cause weird behavior in situations when request fail or return something what is not intended
      // That's why I added {redirect: error} to prevent obvious redirection,
      // Now action will do nothing when redirection occur, imo it's better than displaying weird content, at least for now
    });
}
