import ApplicationController from "./application_controller";
import { confirmation } from "../utils/template_confirmations";

export default class extends ApplicationController {

  renderConfirmation(e) {
    e.preventDefault();

    confirmation(e.target.dataset);
  }
}