console.log('Vite ⚡️ Rails')

import '@hotwired/turbo-rails'

// Import all channels.
const channels = import.meta.glob('../**/*_channel.js', {eager: true});

import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from "../controllers/application_controller";
import CableReady from 'cable_ready'
import debounced from "debounced";

import { Application } from "stimulus";
// import Rails from '@rails/ujs';
// Rails.start();
const application = Application.start()
// Configure Stimulus development experience
application.warnings = true
application.debug = true
window.Stimulus   = application

debounced.initialize({ input: { wait: 500 } });

application.consumer = consumer
StimulusReflex.initialize(application, { 
  consumer, 
  controller, 
  debug: false,
  isolate: true});
CableReady.initialize({ consumer })

const controllers = import.meta.glob("../**/*_controller.js", {eager: true});
for (let path in controllers) {
  let module = controllers[path];
  let name = path.match(/\.\/(.+)_controller\.js$/)[1].replace("controllers/", "").replaceAll("/", "--").replaceAll("_", "-");
  application.register(name, module.default);
}

const designControllers = import.meta.glob("../../components/design/**/*_controller.js", {eager: true});
// const designControllers = import.meta.glob("../../../components/design/**/*_controller.js");

for (let path in designControllers) {
  let module = designControllers[path];
  let name = path.match(/\.\/(.+)_controller\.js$/)[1].replace("../components/design/", "").replaceAll("/", "--").replaceAll("_", "-");
  application.register(name, module.default);
}

import "trix"
import "@rails/actiontext"