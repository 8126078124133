import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import Sortable from "sortablejs";
import { setLayout } from "./masonry_controller";

export default class extends Controller {
  static targets = ["panels", "gridPanel"];

  connect() {
    StimulusReflex.register(this);

    Sortable.create(this.panelsTarget, {
      onEnd: (event) => this.reorder(event),
      delay: 1000, // time in milliseconds to define when the sorting should start
	    delayOnTouchOnly: true, // only delay if user is using touch
      swapThreshold: 0.75,
      animation: 150,
      direction: 'horizontal',
      handle: ".card-panel-sortable"
    });
  }

  reorder(event) {
    this.stimulate("PanelsReflex#reorder", event.item, event.newIndex).then(() => {
      setLayout();
    });
  }
}