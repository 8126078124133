import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    pinned: Boolean,
  }

  checkPinnedThenDelete(e) {
    if (this.pinnedValue) return this.stimulate("ApplicationReflex#render_cable_flash", "This is a pinned note. Please unpin this note then try again.", "alert"); 
    this.stimulate("NoteReflex#delete");
  }
}
