import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  setUserForm(e) {
    const src = e.target.dataset.srcLink;
    const connector = src.includes("?") ? "&" : "?";
    const teamId = e.target.value;
    document.querySelector("#user_form_from_admin_panel").setAttribute("src", `${src}${connector}team_id=${teamId}`);
  }

  setTypeForSearch(e) {
    const searchForm = document.querySelector('#users-search-bar-container');
    const userType = e.target.text.toLowerCase();
    const hiddenInputTypeField = searchForm.querySelector('input[name="type"][type="hidden"]');
    const searchField = searchForm.querySelector('#search-input');

    hiddenInputTypeField.value = userType;
    searchField.value = '';
    Array.from(searchForm.querySelectorAll(['input:not([name="module_type"]):not([name="type"])'])).forEach(el => el.value = '');
  }

  updateRolesForTeam(e) {
    const teamIdSelected = this.element.selectedOptions[0].value;
    const selectorId = e.target.dataset.selectorId;
    const name = e.target.dataset.name;
    const moduleType = e.target.dataset.moduleType;

    fetch(`/admins/roles_for_team?module_type=${moduleType}&team_id=${teamIdSelected}&selector_id=${selectorId}&name=${name}`, { headers: { Accept: "text/vnd.turbo-stream.html" } })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  updateSearchForm(e) {
    const searchForm = document.querySelector('#users-search-bar-container');
    const fieldToUpdate = e.target.dataset.field;
    const valueToUpdate = e.target.value;
    searchForm.querySelector(`#${fieldToUpdate}`).value = valueToUpdate
  }

  changeRole(e) {
    if (this.element.value) {
      var url = `/admins/fetch_role_attributes.json?role_id=${this.element.value}`
      if (this.element.dataset.userUuid) { url = `${url}&user_uuid=${this.element.dataset.userUuid}` }
      fetch(url)
      .then(response => response.json())
      .then(response => this._renderElements(response))
    } {
      document.querySelector('#admin-users-form-role-attributes-row-wrapper').innerHTML = ''
    }
  }

  _renderElements(response) {
    document.querySelector('#admin-users-form-role-attributes-row-wrapper').innerHTML = response.attributes_html

    const rr_assignment_checkbox =  document.getElementById('user_round_robin_assignment')

    if (response.rr_assignment_checkbox.show_element) {
      rr_assignment_checkbox.classList.remove('vanish');
    } else {
      rr_assignment_checkbox.classList.add('vanish');
    }

    if (response.rr_assignment_checkbox.disabled_state) {
      rr_assignment_checkbox.classList.add('disabled');
    } else {
      rr_assignment_checkbox.classList.remove('disabled');
    }
  }
}
