import ApplicationController from "./application_controller";
import { setLayout } from "./masonry_controller";

export default class extends ApplicationController {
  static targets = [ "sortableBtn" ]
  static values = { value: String }

  toggleSortable(e) {
    if (e.target.checked) {
      this.element.classList.add("is-panel-sortable");
      document.querySelector("#background-dimmer").classList.remove("vanish");
      document.querySelectorAll(".card-panel").forEach((panel) => panel.classList.add("card-panel-sortable"));
    } else {
      this.element.classList.remove("is-panel-sortable");
      document.querySelectorAll(".card-panel").forEach((panel) => panel.classList.remove("card-panel-sortable"));
      document.querySelector("#background-dimmer").classList.add("vanish");
      setTimeout(() => {
        
        setLayout();
      }, 400);
    }
  }
}