import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    // Allows the is-selected of a task moving to a new status column to be carried over
    const selectedTaskPanelIsOpen = document.querySelector("#selected-panel").dataset.isOpen == "true";
    if (selectedTaskPanelIsOpen) {
      this.element.classList.add("is-selected");
    }
  }
}