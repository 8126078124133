import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["sortOption"]

  selectSortOption(e) {
    this.sortOptionTargets.forEach(sortOption => {
      sortOption.classList.remove("is-selected");
    });
    e.currentTarget.classList.add("is-selected");
  }
}