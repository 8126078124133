import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    const previousMsg = this.element?.previousElementSibling?.dataset?.controller == "chat-message" ? this.element?.previousElementSibling : this.element?.previousElementSibling?.previousElementSibling
    const isPreviousMsgSameUser = this.element?.dataset?.userId == previousMsg?.dataset?.userId;
    if (isPreviousMsgSameUser) {
      this.element.style.paddingBottom = "0";
      this.element.style.paddingTop = "0";
      super.addVanish(this.element.querySelector(".message-user-name-container"))
      previousMsg.style.paddingBottom = "4px";
      previousMsg.querySelector(".message-user-avatar-container").classList.add("hidden")
    }
  }
}