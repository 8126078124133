import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  updateTeamsForModuleType(e) {
    const moduleType = e.target.value;
    const selectorId = e.target.dataset.selectorId;
    const name = e.target.dataset.name;

    const roleTypeElement = document.getElementById('role_role_type');
    const roleTypeAttributesWrapper = document.querySelector('#role-type-attributes-wrapper');

    fetch(`/admins/teams_for_module_type?module_type=${moduleType}&selector_id=${selectorId}&name=${name}`, { headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))

    if (roleTypeElement) { roleTypeElement.value = 'admin' }
    if (roleTypeAttributesWrapper) { roleTypeAttributesWrapper.innerHTML = '' }
  }

  fetchRoleTypeAttributes(e) {
    const roleType = e.target.value;
    const moduleType = document.getElementById('role_module_type').value;
    const roleId = e.target.dataset.roleId;

    var url = `/admins/roles/fetch_role_type_attributes.json?role_type=${roleType}&module_type=${moduleType}`
    if (roleId) { url = `${url}&role_id=${roleId}` }
    if (moduleType) { url = `${url}&module_type=${moduleType}` }

    fetch(url)
    .then(response => response.json())
    .then(response => document.querySelector('#role-type-attributes-wrapper').innerHTML = response.attributes_html)
  }
}