import { Controller } from "stimulus";
import consumer from "../channels/consumer";

import Tribute from "tributejs"
import Trix from "trix"

import { debounce } from "../utils/debounce";

export default class extends Controller {
  static targets = ["messages", "newMessage", "newMessageSound", "newMessageNotification"];
  static values = {currentUserId: String}

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "MessageChannel", sgid: this.data.get("sgid"), module_type: this.data.get("module-type") },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );

    this.newMessageTarget.addEventListener("trix-initialize", (event) => {
      this.editor = event.target.editor;
    });

    this.initializeTribute()

    window.addEventListener("focus", this.updateLastSeen);
  }
  
  updateLastSeen = event => {
    this.subscription.perform("touch");
  };

  disconnect() {
    window.removeEventListener("focus", this.updateLastSeen);
    this.tribute.detach(this.newMessageTarget)
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {  
    if (data.message) {
      this.messagesTarget.insertAdjacentHTML("beforeend", data.message);
      const noMessagesText = document.getElementById(
        "chatroom-no-messages-text"
      );
      if (noMessagesText) noMessagesText.remove();
      if (!document.hidden) {
        this.subscription.perform("touch");
      }
      this.handleUserMessageStylingAndSound();
    }
  }

  handleUserMessageStylingAndSound() {
    const sentMessage = this.messagesTarget.lastElementChild;
    const userMessageId = sentMessage.dataset.userId;
    const currentUserId = this.messagesTarget.dataset.userId;

    // Current user received the message sent
    if (userMessageId !== currentUserId) {
      sentMessage.classList.remove('is-users-message');
      this.newMessageSoundTarget.play(); // Plays new message ding to alert user of a new message.
    } 
    if (!document.hidden) {
      sentMessage.classList.add('animate-fade-in-left');
      sentMessage.scrollIntoView({block: "nearest", behavior: "smooth"}); // Focuses scroll onto latest message)
    }
  }

  // Called when chatroom-message-container finishes fadeInLeftBig animation from data-action.
  removeAnimation(e) {
    e.target.classList.remove('animate');
    e.target.classList.remove('animate-fade-in-left');
  }

  removeNotification(e) {
    e.target.remove();
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: debounce(this.fetchUsers.bind(this), 200),
    })

    this.tribute.attach(this.newMessageTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.newMessageTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    fetch(`/channels/${this.data.get("sgid")}/users.json?q=${text}&module_type=${this.data.get("module-type")}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - endPos, position])
    this.editor.deleteInDirection("backward")
  }
}
