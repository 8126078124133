import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["panelCheckbox"]

  enableAllPanels(e) {
    this.panelCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = true;
      checkbox.removeAttribute("disabled");
    });
    this.stimulate("PanelManagerReflex#enable_all_panels", e.currentTarget);
  }
}