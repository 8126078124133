import ApplicationController from "./application_controller";
import { fetchModal } from "../utils/fetch_modal";
import { closeModal } from "../utils/close_modal";

export default class extends ApplicationController {
  static targets = [
    "document",
    "docAddBtn",
    "docRequestBtn",
    "docUploadBtn",
    "uploadDocBtn",
  ];
  static values = { 
    uuid: String,
    msg: String,
    docUuid: String,
    callMethod: String,
    clientType: String
  };

  uploadDocs(e) {
    e.preventDefault();

    const docRequestUuid = e.target.getAttribute(
      "data-document-requests-doc-request-uuid"
    );
    const resource = this.uploadDocBtnTarget.getAttribute(
      "data-document-requests-resource"
    );
    const url = `/document_requests/${docRequestUuid}/edit?resource=${resource}`;

    fetchModal(e, url);
  }

  createDocsRequest(e) {
    e.preventDefault();

    const resource = this.docRequestBtnTarget.getAttribute(
      "data-document-requests-resource"
    );
    const moduleType = this.docRequestBtnTarget.getAttribute(
      "data-document-requests-module-type"
    );
    const url = `/document_requests/new_request?resource=${resource}&module_type=${moduleType}`;

    fetchModal(e, url);
  }

  newUpload(e) {
    e.preventDefault();

    const resource = this.docUploadBtnTarget.getAttribute(
      "data-document-requests-resource"
    );
    const moduleType = this.docUploadBtnTarget.getAttribute(
      "data-document-requests-module-type"
    );
    const url = `/document_requests/new_upload?resource=${resource}&module_type=${moduleType}`;

    fetchModal(e, url);
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
  }
}
