import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "contingencySelects" ]
  
  connect() {
    this.contingencySelectsTargets.forEach(select => {
      this.disableSubsequentInputs(select);
    })
  }
  
  disableSubsequentInputs(e) {
    const target = e.target || e;
    const value = target.value;
    const inputIds = target.dataset.subsequentInputIds.split(',');
    if (value == "no" || value == "waived") {
      inputIds.forEach((inputId) => {
        let input = document.querySelector(`#${inputId}`);
        input.setAttribute('disabled', 'disabled');
        input.removeAttribute('required');
        if (input.classList.contains("date")) input.parentElement.querySelector('abbr').classList.add('vanish');
        if (value == "no") input.value = "";
      })
    } else {
      inputIds.forEach((inputId) => {
        let input = document.querySelector(`#${inputId}`);
        input.removeAttribute('disabled');
        if (input.classList.contains("date")) {
          input.setAttribute("required", "required"); // Make removal date required
          input.parentElement.querySelector('abbr').classList.remove('vanish');
        }
      })
    }
  }
}