import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggleRollbackValuesField(e) {
    const doNotRollback = e.currentTarget.checked || document.querySelector('.form-input-checkbox').checked;

    if(doNotRollback) {
      document.querySelector('#hidden-multiselect-cancelation_attr').dataset.doNotRollback = true;
      document.querySelector('#hidden-multiselect-cancelation_values').dataset.doNotRollback = true;
    }
    else {
      document.querySelector('#hidden-multiselect-cancelation_attr').dataset.doNotRollback = false;
      document.querySelector('#hidden-multiselect-cancelation_values').dataset.doNotRollback = false;
    }

    document.querySelector('#hidden-multiselect-cancelation_values').dispatchEvent(new Event("change", { bubbles: true }));
  }
}
