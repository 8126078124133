import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const addressInput = document.querySelector("#exp_transaction_address_one");
    if (addressInput) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
      });
      google.maps.event.addListener(
        autocomplete,
        "place_changed",
        this.onPlaceChanged
      );
      google.maps.event.addDomListener(addressInput, "keydown", (e) => {
        if (e.key === "Enter") e.preventDefault();
      });
    }
  }

  onPlaceChanged() {
    const place = this.getPlace();
    const components = getAddressComponents(place);

    const addressInput = document.querySelector("#exp_transaction_address_one"),
      zipInput = document.querySelector("#exp_transaction_zip"),
      cityInput = document.querySelector("#exp_transaction_city"),
      countyInput = document.querySelector("#exp_transaction_county"),
      googleInput = document.querySelector("#exp_transaction_google_address"),
      stateInput = document.querySelector("#exp_transaction_state");
    [
      addressInput.value,
      zipInput.value,
      cityInput.value,
      stateInput.value,
      countyInput.value,
      googleInput.value,
    ] = [
      components.address_one,
      components.zip_code,
      components.city,
      components.state,
      components.county,
      components.full_address,
    ];
  }
}

function getAddressComponents(place) {
  let street_number = "",
    route = "",
    zip_code = "",
    county = "",
    city = "",
    state = "",
    country_code = "",
    full_address = "";

  for (let i in place.address_components) {
    full_address = place.formatted_address;
    let component = place.address_components[i];
    for (let j in component.types) {
      let type = component.types[j];
      if (type === "street_number") {
        street_number = component.long_name;
      } else if (type === "route") {
        route = component.long_name;
      } else if (type === "postal_code") {
        zip_code = component.long_name;
      } else if (type === "locality") {
        city = component.long_name;
      } else if (type === "administrative_area_level_1") {
        state = component.short_name;
      } else if (type === "postal_town" && city === "") {
        city = component.long_name;
      } else if (type === "administrative_area_level_1" && city === "") {
        // NOTE: For some reasons addresses in New York doesn't return postal_town type nor locality
        city = component.long_name;
      } else if (type === "country") {
        country_code = component.short_name;
      } else if (type === "administrative_area_level_2") {
        county = component.long_name;
      }
    }
  }

  return {
    address_one: `${street_number} ${route}`,
    zip_code: zip_code,
    city: city,
    state: state,
    country_code: country_code,
    county: county,
    full_address: full_address,
  };
}
