import ApplicationController from "./application_controller";
import DOMPurify from "dompurify";

export default class extends ApplicationController {
  static values = { toDoListUuid: String }

  async connect() {
    document.querySelectorAll('a').forEach(l => { l.setAttribute("data-turbo-prefetch", "false")});

    await new Promise(resolve => setTimeout(resolve, 100));
    await this.loadTasks();
  }

  async loadTasks() {
    const tasksLoadedPromises = [];
  
    ["overdue", "outstanding", "completed", "canceled"].forEach(status => {
      tasksLoadedPromises.push(new Promise((resolve, reject) => {
        try {
          fetch(`/to_do_list/${this.toDoListUuidValue}/list_items/${status}`)
          .then(response => response.json())
          .then(data => {
            let taskContainer = document.querySelector(data.tasks_html.selector);
              let counterContainer = document.querySelector(data.counter_html.selector);

              taskContainer.innerHTML = DOMPurify.sanitize(data.tasks_html.html, { ADD_ATTR: ['target'] });
              if (counterContainer) counterContainer.outerHTML = DOMPurify.sanitize(data.counter_html.html);
            })
          } finally {
            resolve();
          }
      }));
    });
  
    // Wait for all tasks to be loaded
    await Promise.all(tasksLoadedPromises);

    // Dispatch the event to tasks_controller so myPrioritiesSelectedTask() can be run after all tasks are loaded
    const tasksFinishedLoadingEvent = new Event('tasksFinishedLoading');
    document.dispatchEvent(tasksFinishedLoadingEvent);  
  }
}
