import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "accordion", 
    "toggleBtn" 
  ]
  static values = { 
    closeText: String,
    isOpen: Boolean,
    reflexMethod: String
  }

  toggleOpen(e) {
    if (this.isOpenValue) {
      this.element.classList.remove("is-open");
    } else {
      this.element.classList.add("is-open");
    }

    this.isOpenValue = !this.isOpenValue;

    this.updateAccessibility();

    this.stimulate("PanelsReflex#switch_visibility", this.isOpenValue);
  }
  
  updateAccessibility() {
    this.toggleBtnTarget.setAttribute("aria-expanded", this.isOpenValue);
    this.accordionTarget.setAttribute("aria-hidden", !this.isOpenValue);
  }
}

export const setAccordionHeight = (accordion) => {
  accordion.style.maxHeight = `${accordion.scrollHeight + 3}px`;
}