import ApplicationController from "./application_controller";
import Calendar from "@toast-ui/calendar";

export default class extends ApplicationController {
static targets = [ "month", "taskCount", "selectedTasksDate", "sidePanel", "pluralization" ]
static values = { moduleType: String }

  initialize() {
    this.calendar = new Calendar('#calendar', {
      defaultView: 'month',
      month: {
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      },
      isReadOnly: true,
      useDetailPopup: false,
      gridSelection: false,
      template: {
        allday(event) {
          return `<span>${event.title}</span>`;
        },
      },
      theme: {
        month: {
          holidayExceptThisMonth: { color: "rgb(51, 51, 51, 0.5)" },
          holiday: { color: "rgb(51, 51, 51)" },
          gridCell: {headerHeight: 40},
          dayName: {height: 60},
        }
      },
      calendars: [
        {
          id: '1',
          name: 'Completed Tasks',
        },
        {
          id: '2',
          name: 'Pending Tasks',
        },
        {
          id: '3',
          name: 'Overdue Tasks',
        },
      ],

    });
    this.fetchTasks();
  }

  connect() {
    super.connect();
    document.addEventListener('handleFilterDispatchEvent', this.fetchTasks.bind(this));
  }

  disconnect() {
    document.removeEventListener('handleFilterDispatchEvent', this.fetchTasks.bind(this));
  }
  
  async fetchTasks() {
    this.calendar.clear();
    try {
      const response = await fetch(`/calendar_tasks.json?module_type=${this.moduleTypeValue}&date=${this.calendar.getDate().d.d}`);
      const tasks = await response.json();
      this.updateMonthName();
      this.calendarTasks = [];
      this.taskDatesAndUUIDs = {};
      tasks.forEach((task) => {
        let taskEventInfo = this.getTaskEventInfo(task.due_date_class);
        let formattedTask = {
          id: task.uuid,
          calendarId: taskEventInfo.calendarId,
          title: task.task,
          category: 'allday',
          dueDateClass: task.due_date_class,
          isAllday: "true",
          backgroundColor: taskEventInfo.bgColor,
          start: task.due_date,
          end: task.due_date,
          isReadOnly: true,
          borderColor: taskEventInfo.primaryColor,
          color: taskEventInfo.primaryColor,
          customStyle: {
            fontSize: "14px",
          }
        };
        this.calendarTasks.push(formattedTask);
        const date = new Date(task.due_date).toLocaleDateString('en-US');
        if (this.taskDatesAndUUIDs[date]) return this.taskDatesAndUUIDs[date].push(task.uuid);
        this.taskDatesAndUUIDs[date] = [task.uuid];
      });
      
      this.calendar.createEvents(
        this.calendarTasks
      )
      this.calendar.on('clickEvent', ({ event }) => {
        this.stimulate("IndexCalendarReflex#render_spinner");
        let pluralization = "s";
        this.element.querySelector(".toastui-calendar-weekday-event-block.is-selected")?.classList?.remove("is-selected")
        this.calendar.getElement(event.id, event.calendarId).classList.add("is-selected")
        this.closeSeeMorePopup();        
        this.openSidePanel();
        const date = new Date(event.start.d.d).toLocaleDateString('en-US'); 
        const dateVerbiage = new Date(event.start.d.d).toLocaleDateString("en-US", {
          month: 'long',  // Full month name (April)
          day: 'numeric', // Day of the month (2 digits with leading zero for single digit)
          year: 'numeric', // Year (4 digits)
        });

        const selectTasksUuids = this.taskDatesAndUUIDs[date];
        this.stimulate("IndexCalendarReflex#find_days_tasks", selectTasksUuids, this.moduleTypeValue, event.id);
        this.taskCountTarget.textContent = selectTasksUuids.length;
        if (selectTasksUuids.length == 1) pluralization = "" 
        this.pluralizationTarget.textContent = pluralization;
        this.selectedTasksDateTarget.textContent = dateVerbiage;
      });
      this.calendar.on('clickMoreEventsBtn', (event) => {
        let moreEventsPopup = this.element.querySelector('.toastui-calendar-see-more-container');
        if (!moreEventsPopup.style.display || moreEventsPopup.style.display == "none") {
          moreEventsPopup.style.display = 'block';
        } 
      });
    } catch (err) {
    }
  }

  closeSeeMorePopup() {
    if (this.element.querySelector('.toastui-calendar-see-more-container')){
      this.element.querySelector('.toastui-calendar-see-more-container').style.display = "none";
    }
  }

  updateTask(e) {
    const toggleSwitchInput = e.currentTarget.querySelector("input")
    const existingStatus = toggleSwitchInput.dataset.overdue == "true" && toggleSwitchInput.dataset.completed == "false" ? "overdue" : toggleSwitchInput.dataset.completed == "true" ? "completed" : "pending";
    let calendarId, taskEventInfo;
    if (toggleSwitchInput.checked) {
      calendarId = "1";
      taskEventInfo = this.getTaskEventInfo("completed");
    } else {
      if (toggleSwitchInput.dataset.overdue == "true") {
        calendarId = "3" 
        taskEventInfo = this.getTaskEventInfo("overdue");
      } else {
        calendarId = "2";
        taskEventInfo = this.getTaskEventInfo("pending");
      }
    }
    this.calendar.updateEvent(toggleSwitchInput.dataset.listItemUuid, this.getTaskCalendarId(existingStatus),
      {
        calendarId: calendarId,
        backgroundColor: taskEventInfo.bgColor,
        borderColor: taskEventInfo.primaryColor,
        color: taskEventInfo.primaryColor,
      }
    )
  }

  updateMonthName() {
    var currentDate = this.calendar.getDate().d.d;
    var monthYear = currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
    this.monthTarget.textContent = monthYear;
    this.closeSeeMorePopup();
  }

  openSidePanel() {
    this.sidePanelTarget.classList.remove("vanish");
    this.sidePanelTarget.style.removeProperty('--side-panel--width', '0px');
  }

  closeSidePanel() {
    this.element.querySelector(".toastui-calendar-weekday-event-block.is-selected")?.classList?.remove("is-selected");
    this.sidePanelTarget.style.setProperty('--side-panel--width', '0px');
    this.sidePanelTarget.addEventListener('transitionend', (e) => {
      if (e.propertyName === 'width') {
        this.sidePanelTarget.classList.add('vanish');
      }
    });
  }

  nextMonth() {
    this.calendar.next();
    this.fetchTasks();
    this.updateMonthName();
  }

  prevMonth() {
    this.calendar.prev();
    this.fetchTasks();
    this.updateMonthName();
  }

  focusToday() {
    this.calendar.today();
    this.fetchTasks();
    this.updateMonthName();
  }

  getTaskEventInfo(status) {
    return {
      bgColor: this.getTaskBgColor(status),
      primaryColor: this.getTaskPrimaryColor(status),
      calendarId: this.getTaskCalendarId(status)
    }
  }

  getTaskCalendarId(status) {
    switch (status) {
      case 'overdue':
        return '3';
      case 'pending':
        return '2';
      case 'completed':
        return '1';
      default:
        return '2'; // Covers due-tomorrow/due-today cases
    }
  }

  getTaskPrimaryColor(status) {
    switch (status) {
      case 'overdue':
        return '#D93149';
      case 'pending':
        return '#0175DB';
      case 'completed':
        return '#28A745';
      default:
        return '#0175DB';
    }
  }

  getTaskBgColor(status) {
    switch (status) {
      case 'overdue':
        return '#FAE3E8';
      case 'pending':
        return '#F2F8FD';
      case 'completed':
        return '#EBF7ED';
      default:
        return '#F2F8FD';
    }
  }
}