import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  submitFilter(e) {
    const filterName = e.target.dataset.filterId;
    const filterType = e.target.dataset.filterType;
    const filterAttrType = e.target.dataset.filterAttrType;
    const resourceType = e.target.dataset.resourceType;
    const savedFilters = JSON.parse(e.target.dataset.savedFilters) || {};
    const moduleType = e.target.dataset.moduleType;
    const filterId = filterName.replace(".", "-");
    const filterOperator = document.querySelector(`#filter_operator_${filterId}`).value;

    let minVal, maxVal, value;
    let formIsValid = true;

    this.element.querySelectorAll('.range-input-fields-container input').forEach((el) => { 
      if (el.value) return; 
      
      el.classList.add('has-errors');
      formIsValid = false;
      el.addEventListener('focus', (e) => {
        e.target.classList.remove('has-errors');
      });
    });

    if (filterOperator === 'In Between') {
      minVal = document.querySelector(`#${filterId}_min`)?.value;
      maxVal = document.querySelector(`#${filterId}_max`)?.value
    }
    else {
      value = document.querySelector(`#${filterId}_eq`)?.value;
    }

    if (!formIsValid) return super.renderToast(
      this.element.id, "Please fill in all the fields in red.", "warning", false, null, 4500, true
    );

    this.stimulate(
      "RangeInputReflex#submit_filter",
      filterName,
      filterType,
      filterAttrType,
      savedFilters,
      moduleType,
      filterOperator,
      value,
      minVal,
      maxVal,
      resourceType
    );
  }

  setAuditLogsDateValue(e) {
    const filterName = e.target.dataset.filterId;
    const filterId = filterName.replace(".", "-");
    const filterOperator = document.querySelector(`#filter_operator_${filterId}`).value;

    let minVal, maxVal, value;
    let formIsValid = true;

    this.element.querySelectorAll('.range-input-fields-container input').forEach((el) => {
      if (el.value) return;

      el.classList.add('has-errors');
      formIsValid = false;
      el.addEventListener('focus', (e) => {
        e.target.classList.remove('has-errors');
      });
    });

    if (filterOperator === 'In Between') {
      minVal = document.querySelector(`#${filterId}_min`)?.value;
      maxVal = document.querySelector(`#${filterId}_max`)?.value
    } else {
      value = document.querySelector(`#${filterId}_eq`)?.value;
    }

    if (!formIsValid) return super.renderToast(
      this.element.id, "Please fill in all the fields in red.", "warning", false, null, 4500, true
    );

    this.stimulate(
      "RangeInputReflex#set_audit_logs_date_value",
      filterName,
      filterOperator,
      value,
      minVal,
      maxVal,
    );
  }
}