import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "select", "internal", "external" ]

  initialize() {
    if (this.hasSelectTarget) {
      const internalContainer = this.internalTarget;
      const externalContainer = this.externalTarget;
      //! If needed, rewrite this into multiselect design component
      // new lc_select(this.selectTarget, {
      //   enable_search: false,
      //   min_for_search: 1,
      //   autofocus_search: false,
      //   wrap_width: 'inherit',
      //   on_change: function(newValue, _select) {
      //     const hasReferralSelected = newValue.includes("Internal Referral") || newValue.includes("External Referral");

      //     toggleRequired(internalContainer, newValue.includes("Internal Referral"), newValue.includes("N/A"));
      //     toggleRequired(externalContainer, newValue.includes("External Referral"), newValue.includes("N/A"));   
      //     // Makes N/A override referrals selection
      //     if (newValue.includes("N/A")) toggleDisabledAndResync(hasReferralSelected)
      //   }
      // })
    }

    const toggleRequired = (element, enable, disableAll) => {
      const input = element.querySelector("select");
      const referralType = element.getAttribute("data-referral-type");
      const amountContainerEl = document.querySelector(`#${referralType}-referral-amount-container`);
      const amountInputEl = amountContainerEl.querySelector("input");

      let percentageInputEl;
      const percentageContainerEl = document.querySelector(`#${referralType}-referral-amount-percentage-container`);
      if (percentageContainerEl) percentageInputEl = percentageContainerEl.querySelector("input");

      if (disableAll) {
        this.disableEl(input, amountInputEl, percentageContainerEl, element, amountContainerEl);
        return
      }

      if (enable) {
        [element, amountContainerEl].forEach(el => super.removeVanish(el));
        [input, amountInputEl].forEach(el => el.setAttribute("required", true));
      }  else {
        this.disableEl(input, amountInputEl, percentageContainerEl, element, amountContainerEl);
      }
    }

    const toggleDisabledAndResync = (showToast = false) => {
      this.selectTarget.value = "N/A";
      const resyncEvent = new Event('lc-select-refresh');
      this.selectTarget.dispatchEvent(resyncEvent);
      if (showToast) super.renderToast(this.selectTarget.id, "Please select either only N/A or referral types, not both")
    }
  }


  disableEl(input, amountInputEl, percentageContainerEl, element, amountContainerEl) {
    [input, amountInputEl, percentageContainerEl].forEach(el => el.value = '');
    [input, amountInputEl, percentageContainerEl].forEach(el => el.removeAttribute("required"));
    [element, amountContainerEl, percentageContainerEl].forEach(el => super.addVanish(el));
  }
}