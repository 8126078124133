import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    reflexName: String,
    selector: String
  }

  toggleExpandedView(e) {
    this.stimulate("IndexTablesReflex#toggle_tasks_view", e.currentTarget).then((reflex) => {
      const width = document.querySelector(this.selectorValue).clientWidth - 32;
      const id = reflex.data.dataset.dataset['data-id'];
      document.querySelectorAll(`.tasks-table-row-${id}`).forEach(row => {
        row.querySelector(".task-row").style.width = `${width}px`;
      })
    })
  }
}