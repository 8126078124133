export function toggleHTMLError(isValid, el, errorMsg, id, inputType) {
  if (isValid) {
    document?.querySelectorAll(id).forEach((el) => el.remove());
    const errorElements = document?.getElementsByClassName(
      `input ${inputType} has-errors`
    );
    for (let index = 0; index < errorElements.length; index++) {
      errorElements[index].classList.remove("has-errors");
    }
    return;
  }
  const modalFooter = document.querySelector(".modal-card-footer");
  modalFooter.insertAdjacentHTML("afterBegin", errorMsg);
  el.parentElement.classList.add("has-errors");
}
