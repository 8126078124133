import ApplicationController from "./application_controller";
import { setLayout } from "./masonry_controller";

export default class extends ApplicationController {
  initialize() {
    const subNav = document.querySelector('#sub-nav');
    const navbarComponent = document.querySelector('#navbar-component');
    const titleBarContainer = document.querySelector('.title-bar-container');
    this.selectedPanel = document.querySelector('.selected-panel--container');

    let topVal = 0;

    const updateTop = (isIntersecting, height) => {
      if (isIntersecting) {
        topVal += height;
      } else {
        topVal -= height;
      }
      topVal < 0 ? topVal = 0 : topVal = topVal; // If elements are out of screen when page loads like on a F5
      setSelectedPanelVariable("container-top", `${topVal}px`);
      this.selectedPanel.style.height = `calc(100vh - ${topVal}px`;
    }

    const handleIntersect = (entries) => {
        entries.forEach(entry => {
          updateTop(entry.isIntersecting, entry.boundingClientRect.height);
        });
    };

    const observer = new IntersectionObserver(handleIntersect);
    [subNav, navbarComponent, titleBarContainer].forEach(element => {
      observer.observe(element);
    })
  }

  closeSelectedPanel() {
    toggleSelectedPanel();
    removeSelectedCard();
  }

  updateWidth() {
    if (this.element.dataset.isOpen == "false") return;
    updateWidth(this.element)
  }

  toggleTaskStatus(e) {
    const changeEvent = new Event('change');
    const id = e.currentTarget.dataset.taskItemId;
    this.element.querySelector(`#to_do_checkbox_${id}`).dispatchEvent(changeEvent);
  }
}

// Trigger update of page layout, panel CSS, attr updates, and animations
export const toggleSelectedPanel = () => {
  const selectedPanel = document.getElementById("selected-panel");
  const panelIsOpen = selectedPanel.dataset.isOpen == "true";

  if (panelIsOpen) {
    animateSelectedPanelOut();
    setTimeout(() => {
      updatePageLayout(selectedPanel);
      setSelectedPanelVariable("container-display", `none`);
    }, 490);
  } else {
    updatePageLayout(selectedPanel);
    setTimeout(() => {
      setSelectedPanelVariable("container-display", `block`);
      animateSelectedPanelIn();
      updateContentHeight(selectedPanel);
    }, 275);
  }
  selectedPanel.dataset.isOpen = !panelIsOpen;
}

// Update the actual grid of the page and the masonry aspect
export const updatePageLayout = (selectedPanel) => {
  selectedPanel.classList.toggle("vanish")
  document.getElementById("panels-grid-container").classList.toggle("has-selected-task")
  setTimeout(() => {
    setLayout();
  }, 20)
}

// Sets the selected task panel content height minus the footer and header
export const updateContentHeight = (selectedPanel) => {
  const selectedPanelContent = selectedPanel?.querySelector(".selected-panel--content");
  const selectedPanelFooter = selectedPanel?.querySelector(".selected-panel--footer");
  if (!selectedPanelContent || !selectedPanelFooter) return;
  const footerAndHeaderHeight = selectedPanelFooter.clientHeight + selectedPanelContent.offsetTop;
  
  setSelectedPanelVariable("-content-height", `calc(100% - ${footerAndHeaderHeight}px)`);
}

export const updateWidth = (selectedPanel) => {
  setSelectedPanelVariable("container-width", `${selectedPanel.clientWidth}px`);
}

// Remove existing is-selected task card
export const removeSelectedCard = () => {
  document.querySelector(".selectable-card.is-selected")?.classList?.remove("is-selected");
}

const animateSelectedPanelIn = () => {
  const selectedPanel = document.getElementById("selected-panel");

  updateWidth(selectedPanel);
  setSelectedPanelVariable("container-transform", "translate3d(0, 0, 0)");
}

const animateSelectedPanelOut = () => {
  setSelectedPanelVariable("container-transform", "translate3d(100%, 0, 0)");
}

const setSelectedPanelVariable = (prop, val) => {
  document.documentElement.style.setProperty(`--selected-panel-${prop}`, val);
}