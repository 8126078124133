import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  checkSearchableCount(e) {
    e.preventDefault();

    let count = 0;
    document.querySelectorAll(".data-manager-index-search-checkbox").forEach((checkbox) => {
      if (checkbox.checked) count += 1;
    })

    if (count > 2) {
      e.target.checked = false;
      super.renderToast(
        this.element.id, "You can only select 2 searchable attributes", "danger", false, null, 4500, true
      );
    }

  }

  cleanUpDataAddNewDataFieldModals() {
    document.querySelectorAll("#modal-component-add-new-data-field").forEach((modal) => {
      modal.remove();
    })
  }

  enableEditing() {
    const moduleType = this.element.dataset.moduleType;
    
    fetch(`/admins/data_manager/enable_form?module_type=${moduleType}`)
      .then(response => response.json())
      .then(data => {
        let container = document.querySelector("#module-manager-form-container");
        container.innerHTML = data.html;

        document.querySelector("#form-edit-btns-container").classList.remove("vanish");
        const editBtn = document.querySelector("#data-manager-edit-btn");
        editBtn.classList.add("vanish");
        editBtn.textContent = "Edit";
        document.querySelector(".module-manager-tabs-container").setAttribute("data-module-manager-tabs-show-modal-warning-value", "true")
      })
      .catch(error => console.log(error))
  }
}