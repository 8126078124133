import ApplicationController from "./application_controller";
import { fetchModal } from "../utils/fetch_modal";

export default class extends ApplicationController {
  static targets = [
    "review",
  ];
  
  showModal(e) {
    e.preventDefault();

    const docRequestUuid = this.reviewTarget.getAttribute("data-document-reviews-uuid");
    const mode = this.reviewTarget.getAttribute("data-document-reviews-mode");
    const url = mode === 'new' ? 
      `/document_reviews/new?uuid=${docRequestUuid}` :
      `/document_reviews/${docRequestUuid}/edit`

    fetchModal(e, url);
  }
}
