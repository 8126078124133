import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  
  closeSidenav() {
    closeSidenav()
  }
  
  // Method names below are provided by sidenav_open_button_component via it's data-action attribute
  openOverlaySidenav() {
    const sidenav = document.querySelector('#sidenav')
    const deviceWidth = document.documentElement.clientWidth
    // If on phone, make full width
    deviceWidth < 768 ? sidenav.style.width = "100%" : sidenav.style.width = "calc(100vw - 50% + 50px)"
  }

  openFixedOverlaySidenav() {
    const sidenav = document.querySelector('#sidenav')
    sidenav.style.width = "250px"
  }

  openPushSidenav() {
    const sidenav = document.querySelector('#sidenav')
    const main = document.querySelector("main")
    sidenav.style.width = "250px"
    main.style.marginLeft = "250px"
  }

  openFullSidenav() {
    const sidenav = document.querySelector('#sidenav')
    const main = document.querySelector("main")
    sidenav.style.width = "100%"
  }
}
export const closeSidenav = () => {
  const sidenav = document?.querySelector('#sidenav')
  const main = document?.querySelector("main")
  if (sidenav) sidenav.style.width = "0";
  main.style.marginLeft = "0"
}