import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["emailSwitch"];
  static values = {
    notificationState: String
  }

  changeNotifications() {
    const titleAction = this.notificationStateValue;
    const action = titleAction.toLowerCase();
    const verbiage = this.emailSwitchTarget.checked ? `from` : `to`;
    if (result.value) return this.stimulate("UsersReflex#change_notification_state", action);
    this.emailSwitchTarget.checked = !this.emailSwitchTarget.checked;
  }
}
