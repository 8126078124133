import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["panel"];
  
  connect() {
    super.connect();
    setLayout();
    this.resizeObservers = this.panelTargets.map((panel) => {
      let resizeObserver = new ResizeObserver(() => {
        setLayout();
      });
      resizeObserver.observe(panel);
      return resizeObserver;
    })
  }

  disconnect() {
    this.resizeObservers.forEach(resizeObserver => resizeObserver.disconnect());
  }
}

export const setLayout = () => {
  const pageGrid = document.querySelector("#panels-grid-container") 
  const panelGrid = document.querySelector("#panels-container") 
  let grids = pageGrid.classList.contains("has-selected-task") ?
      [panelGrid]
    :
      [pageGrid];
  if (grids.length && getComputedStyle(grids[0]).gridTemplateRows !== 'masonry') {
    grids = grids.map(grid => ({
      element: grid, 
      gap: parseFloat(getComputedStyle(grid).rowGap), 
      gridItems: [...grid.querySelectorAll(".card-panel .card-component")],
      colNumber: 0
    }));

    updateLayout(grids);

    window.addEventListener('resize', () => updateLayout(grids), false);
  }
}

const updateLayout = (grids) => {
  grids.forEach(grid => {
    let colNumber = getComputedStyle(grid.element).gridTemplateColumns.split(' ').length;
    if(grid.colNumber !== colNumber) {
      grid.colNumber = colNumber;
      grid.gridItems.forEach(c => c.style.removeProperty('margin-top'));

      if(grid.colNumber > 1) {
        grid.gridItems.slice(colNumber).forEach((c, i) => {
          let prev_fin = grid.gridItems[i].getBoundingClientRect().bottom;
          let curr_ini = c.getBoundingClientRect().top
          c.style.marginTop = `${prev_fin + grid.gap - curr_ini}px`
        })
      }
    }
  })
}
