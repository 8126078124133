import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    const newMsgNotifications = document.querySelectorAll(".new-messages-container");    
    if (newMsgNotifications.length > 1) {
      newMsgNotifications.forEach((item) => {
          if (item !== newMsgNotifications.item(0)) item.remove();
        })
      }
  }
}