import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "messageContent", "smsRecipientContent", "emailRecipientContent", "emailCCRecipientContent", "emailSubjectContent" ]

  static values = {
    targetName: String
  }

  insertAttribute(e) {
    const attribute = e.currentTarget.dataset.key;
    let targetName;
    switch (e.currentTarget.dataset["targetNameValue"]) {
      case "messageContent":
        targetName = this.messageContentTarget;
        break;
      case "smsRecipientContent":
        targetName = this.smsRecipientContentTarget;
        break;
      case "emailRecipientContent":
        targetName = this.emailRecipientContentTarget;
        break;
      case "emailCCRecipientContent":
        targetName = this.emailCCRecipientContentTarget;
        break;
      case "emailSubjectContent":
        targetName = this.emailSubjectContentTarget;
        break;
      default:
        console.log(`${e.currentTarget.dataset["targetNameValue"]}`);
    }
    const textareaInput = targetName; 
    const cursorPosition = this.getCursorPosition(textareaInput);

    textareaInput.focus()

    if (textareaInput.setRangeText) {
      // setRangeText is supported
      textareaInput.setRangeText(attribute, cursorPosition["start"], cursorPosition["end"], "end")
    } else {
      document.execCommand('insertText', false, attribute);
    }
  }

  getCursorPosition(textarea) {
    if (textarea.selectionStart || textarea.selectionStart == '0') {
      return {'start': textarea.selectionStart, 'end': textarea.selectionEnd };
    }
    return { 'start': 0, 'end': 0 }; 
  }
}