import ApplicationController from "./application_controller";
import { toggleValidity } from "../utils/toggle_validity";
import { errorMsg } from "../utils/error_msg";
import { enableSubmitBtn } from "../utils/enable_submit_btn";
import { validateTcUsStates } from "../utils/validate_tc_us_states";

export default class extends ApplicationController {
  static targets = [
    "name", 
    "contractDateSelect",
    "closingDateSelect",
    "stateInput",
    "email",
    "type",
  ];

  checkDocReqName() {
    const name = this.nameTarget;
    const btnId = document
      .querySelector("[data-btnId]")
      .getAttribute("data-btnId");
    const submitBtn = document.querySelector(btnId);
    const isValid = name.value.trim().length !== 0;
    let err = errorMsg("date-error", "Please fix Document Name");
    toggleValidity(isValid, submitBtn, name, err, "#date-error", '');
  }

  checkDate(e) {
    if (document.querySelector(`[data-to-do-list-state-type-field]`).value !== "active_listing") {
      function clearErrors() {
        document?.querySelectorAll("#date-error").forEach((el) => el.remove());
        submitBtn.removeAttribute("disabled");
      }
      
      const contractDate = new Date(this.contractDateSelectTarget.value);
      const closingDate = new Date(this.closingDateSelectTarget.value);
      const submitBtn = document.querySelector("#create_transaction");
      
      let err;
      if (contractDate == "Invalid Date") {
        err = errorMsg("date-error", "Please fix Contract Date</span><br><small>Contract Date is not a valid date.</small>");
        clearErrors();
        toggleValidity(isValid, submitBtn, this.contractDateSelectTarget, err, "#date-error", "date");
        return;
      }
      if (this.closingDateSelectTarget.value == "") {
        return;
      }
      if (closingDate == "Invalid Date") {
        err = errorMsg("date-error", "Please fix Closing Date</span><br><small>Closing Date is not a valid date.</small>");
        clearErrors();
        toggleValidity(isValid, submitBtn, this.closingDateSelectTarget, err, "#date-error", "date");
        return;
      }

      const isContractDateValid = closingDate >= contractDate;
      const isClosingDateInFuture = closingDate >= new Date();
      const isValid = isContractDateValid && isClosingDateInFuture;

      if (!isContractDateValid) {
        err = errorMsg("date-error", "Please fix Closing/Contract Date</span><br><small>Closing Date must be after Contract Date.</small>");
      }
      if (!isClosingDateInFuture) {
        err = errorMsg("date-error", "Please fix Closing Date</span><br><small>Closing Date must be in future.</small>");
      }

      enableSubmitBtn(submitBtn, "#date-error");
      clearErrors();
      toggleValidity(isValid, submitBtn, this.closingDateSelectTarget, err, "#date-error", "date");
    }
  }
  
  validateTcState() {
    const trxTypeValue = document.querySelector("#transaction-form-trx-types").value;
    if (trxTypeValue === 'bpo') { return }

    validateTcUsStates(this.typeTargets, this.emailTargets, this.stateInputTarget);
  }

  // because it need to run after removeField action on remove-participant button
  delayedValidateTcState() {
    setTimeout(() => {
      validateTcUsStates(this.typeTargets, this.emailTargets, this.stateInputTarget);
    }, 1000);
  }
}
