import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "subpanelCheckbox", "panelCheckbox" ]
  static values = {
    subPanel: Boolean,
    isParentPanel: Boolean,
    parentPanelSelector: String
  }

  initialize() {
    if (this.subPanelValue) {
      document.querySelector(this.parentPanelSelectorValue).insertAdjacentHTML('beforeend', this.element.innerHTML);
      this.element.remove();
    }
    setTimeout(() => {
      if (this.isParentPanelValue && !this.panelCheckboxTarget.checked) {
        this.subpanelCheckboxTargets.forEach((checkbox) => {
          checkbox.setAttribute("disabled", true)
        })
      }
    }, 300);
  }

  checkSubpanels(e) {
    if (e.currentTarget.checked) {
      this.subpanelCheckboxTargets.forEach((checkbox) => {
        checkbox.removeAttribute("disabled")
      })
      this.stimulate("PanelManagerReflex#toggle_panel", e.currentTarget);
      return;
    }

    this.subpanelCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.setAttribute("disabled", true)
    });
    
    const subpanelTypes = this.subpanelCheckboxTargets.map((subpanel) => subpanel.dataset.type);
    this.stimulate("PanelManagerReflex#toggle_panels", e.currentTarget, [...subpanelTypes, e.currentTarget.dataset.type]);
  }

  checkTab(e) {
    const toggleSwitch = e.currentTarget;
    const hasLinkedTab = toggleSwitch.dataset.toggleTab == "true";
    if (hasLinkedTab) {
      document.querySelector(`#${toggleSwitch.dataset.type}-tab .tab`).toggleAttribute("disabled");
    }
  }
}