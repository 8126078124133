import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 300)
  }

  submit() {
    this.element.requestSubmit();
  }

  resetFilters(event) {
    event.preventDefault();
    const form = document.querySelector('#search-self-submit-form');
    form.reset();

    Array.from(form.querySelectorAll(['select'])).forEach(el => el.selectedIndex = 0);
    Array.from(form.querySelectorAll(['input:not([type="hidden"])'])).forEach(el => el.value = '');
    
    const searchInputField = document.querySelector('#search-input-container');
    Array.from(searchInputField.querySelectorAll(['input:not([name="module_type"]):not([name="type"])'])).forEach(el => el.value = '');

    form.requestSubmit();
  }

  dispatchEventInput(event) {
    event.target.dispatchEvent(new Event("input", { bubbles: true }))
  }
}
