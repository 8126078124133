import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["tasks"];

  connect() {
    StimulusReflex.register(this);

    Sortable.create(this.tasksTarget, {
      onEnd: (event) => this.reorder(event),
    });
  }

  reorder(event) {
    this.stimulate("TaskReflex#reorder", event.item, event.newIndex);
  }
}