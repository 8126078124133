import { checkTcState } from "./check_tc_state";

export function validateTcUsStates(userTypes, emails, stateInput) {
  const userTypeWithEmailTargets = userTypes.map((type, i) => {
    return {type: type, email: emails[i]};
  });

  let isValid = true;
  userTypeWithEmailTargets.forEach((typeEmailObj) => {
    if (isValid) {
      isValid = checkTcState(stateInput, typeEmailObj.email.value, typeEmailObj.type.value);
    }
  })
}