// Since Safari has terrible native Datepicker support, we turn the input into a manual text input and depend on html5 validation pattern matching instead.
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
static targets = [ "contractDateSelect", "closingDateSelect", "dateHint"]

  initialize() {
    if (navigator.userAgent.indexOf("Chrome") != -1 ) {
      return;
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1 ) {
      return;
    }
    else {
      this.updateInputsForSafari(this.contractDateSelectTarget, this.closingDateSelectTarget);
      this.dateHintTargets.forEach((hint) => {
        hint.textContent = "yyyy-mm-dd"
      })
    }
  }

  updateInputsForSafari(...selectInputs) {
    selectInputs.forEach((input) => {
      input.removeAttribute("type");
      input.setAttribute("pattern", "[0-9]{4}-[0-9]{2}-[0-9]{2}")
      input.title = "YYYY-MM-DD eg: '2021-12-31'"
    })
  }
}