import ApplicationController from "./application_controller";
import { fetchModal } from "../utils/fetch_modal";

export default class extends ApplicationController {
  toggleSelectAllCheckbox(e) {
    let toggle = e.currentTarget.checked
    let isDisabled = !toggle
    let selectAll = toggle

    document.querySelectorAll(".transactions-checkbox").forEach((i) => {
      i.value = toggle
      i.checked = toggle
    });

    this.setLoadMoreButtonSelectAllDataAttr(toggle)

    this.stimulate(
      "ReportsReflex#render_download_report_link",
      [],
      isDisabled,
      selectAll
    )
  }

  listTrxCheckbox() {
    let transactionIds = []
    document.querySelectorAll(".transactions-checkbox:checked")
            .forEach((i) => { transactionIds.push(i.dataset['transactionId']) })
    document.querySelector("#select-all").checked = false

    this.setLoadMoreButtonSelectAllDataAttr(false)

    let isDisabled = transactionIds.length === 0

    this.stimulate(
      "ReportsReflex#render_download_report_link",
      transactionIds,
      isDisabled
    )
  }

  renderDownloadReportLinkSuccess() {
    this.setCsrfToken();
  }

  filterTrxSuccess() {
    this.setCsrfToken();
  }

  setLoadMoreButtonSelectAllDataAttr(value) {
    let loadMoreButton = document.getElementById("reports-load-more-transactions-button")
    if (loadMoreButton) {
      loadMoreButton.setAttribute('data-select-all', value)
    }
  }

  setCsrfToken() {
    const metaCsrfEl = document.querySelector('meta[name="csrf-token"]');
    const formCsrf = document.querySelector('input[name="authenticity_token"]');
    formCsrf.value = metaCsrfEl.content
  }

  updateDateFieldLabels(e) {
    e.preventDefault();

    const reportType = e.currentTarget.value;
    const startDate = document.getElementById("reports_start_date_label");
    const endDate = document.getElementById("reports_end_date_label");

    if (!startDate && !endDate) return;

    if (reportType === "completed_tasks") {
      startDate.firstElementChild.textContent = "Task Completed Start Date";
      endDate.firstElementChild.textContent = "Task Completed End Date";
    } else if (reportType == 'custom_data_export_tasks') {
      startDate.firstElementChild.textContent = "Task Created Start Date";
      endDate.firstElementChild.textContent = "Task Created End Date";
    } else {
      startDate.firstElementChild.textContent = "Start Date";
      endDate.firstElementChild.textContent = "End Date";
    }
  }

  connect(){
    super.connect();
    document.addEventListener('enableGenerate', this.enableGenerate.bind(this));
  }

  enableGenerate(e) {
    const selectedReportType = document.querySelector('#report_type').value;
    const submitBtn = document.querySelector('#onb_reports_submit_btn');
  
    const reportChecks = {
      'onb_team_status_report': () => this.checkFieldNotEmpty('#team_name'),
      'onb_specialist_productivity_report': () => this.checkFieldNotEmpty('#hidden-multiselect-state'),
      'onb_specialist_report': () => this.checkFieldsNotEmpty('#users_ids', '#hidden-multiselect-months')
    };
  
    if (reportChecks[selectedReportType] && reportChecks[selectedReportType]()) {
      submitBtn.classList.remove('disabled');
    } else {
      submitBtn.classList.add('disabled');
    }
  }

  checkFieldNotEmpty(selector) {
    const field = document.querySelector(selector);
    return field && field.value.trim() !== '';
  }

  checkFieldsNotEmpty(...selectors) {
    return selectors.every(selector => this.checkFieldNotEmpty(selector));
  }
}