import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input", "counter", "counterWrapper"];
  static values = {
    countdown: Boolean,
    starting: Number,
    disableSelectorQuery: String,
    warningMessageSelectorQuery: String
  };

  initialize() {
    this.update = this.update.bind(this);
  }

  connect() {
    super.connect();
    this.update();

    this.inputTarget.addEventListener("input", this.update);
    this.inputTarget.addEventListener("focus", this.update.bind(this));
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update);
  }

  update() {
    this.counterTarget.textContent = this.count.toString();
    let charsExceeded = this.count > Number(this.inputTarget.dataset.maxLength);

    this.toggleClasses(charsExceeded);
  }

  toggleElementToDisable(charsExceeded) {
    const selectedElement = this.msgExceedDisableElement;

    if (selectedElement) {
      if (charsExceeded) {
        selectedElement.classList.add("disabled");
      } else {
        selectedElement.classList.remove("disabled");
      }
    }
  }

  toggleWarningMessageVisibility(charsExceeded) {
    const selectedElement = this.warningMsgElement;
    let showMsg = !charsExceeded && (this.count >=  Number(this.inputTarget.dataset.warningLength));

    if (selectedElement) {
      if (!charsExceeded && showMsg) {
        selectedElement.classList.remove("vanish");
        this.counterWrapperTarget.classList.add("warning");
      } else {
        selectedElement.classList.add("vanish");
        this.counterWrapperTarget.classList.remove("warning");
      }
    }
  }

  toggleDangerMessageVisibility(charsExceeded) {
    const selectedElement = this.dangerMsgElement;

    if (selectedElement) {
      if (charsExceeded) {
        selectedElement.classList.remove("vanish");
        this.counterWrapperTarget.classList.add("danger");
      } else {
        selectedElement.classList.add("vanish");
        this.counterWrapperTarget.classList.remove("danger");
      }
    }
  }

  toggleClasses(charsExceeded) {
    if (!this.inputTarget.hasAttribute('disabled')) {
      if (this.hasDisableSelectorQueryValue) {
        this.toggleElementToDisable(charsExceeded);
      }

      if (this.hasWarningMessageSelectorQueryValue) {
        this.toggleWarningMessageVisibility(charsExceeded);
      }

      this.toggleDangerMessageVisibility(charsExceeded);
    }
  }

  get count() {
    let value = this.inputTarget.value.length;

    if (this.hasStartingValue) {
      value = Math.max(this.startingValue + Number(value), 0);
    }

    if (this.hasCountdownValue) {
      if (this.maxLength < 0) {
        console.error(
          `[stimulus-character-counter] You need to add a maxlength attribute on the input to use countdown mode. The current value is: ${this.maxLength}.`
        );
      }

      value = Math.max(this.maxLength - value, 0);
    }

    return value;
  }

  get warningMsgElement() {
    return document.querySelector(this.warningMessageSelectorQueryValue);
  }

  get dangerMsgElement() {
    return document.querySelector("#character-exceeded-danger-message")
  }

  get msgExceedDisableElement() {
    return document.querySelector(this.disableSelectorQueryValue)
  }
}
