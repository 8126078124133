import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  toggleTeams(e){
    const element = document.querySelector(`#${e.target.dataset.moduleType}_teams`);

    if (e.target.checked == false){
      super.addVanish(element);
    } else {
      super.removeVanish(element);
    }

  }
}