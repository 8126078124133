import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [ "addAttributesBtn", "attributeBtn", "frameWindow" ]

  closeFrame(e) {
    e.preventDefault();
    this.frameWindowTarget.classList.remove("animate-zoom-in");
    this.frameWindowTarget.classList.add("animate-zoom-out");
    setTimeout(() => {
      this.frameWindowTarget.remove();
      this.addAttributesBtnTarget.classList.remove("is-selected");
    }, 250)
  }
  
  toggleState(e) {
    if (this.addAttributesBtnTarget.classList.contains("is-selected")) this.closeFrame(e);
    this.addAttributesBtnTarget.classList.toggle("is-selected");
  }

  search(e) {
    const searchValue = e.target.value.toUpperCase();
    let attribute;

    this.attributeBtnTargets.forEach((button) => {
      attribute = button.textContent || button.innerText;
      if (attribute.toUpperCase().indexOf(searchValue) > -1) {
        button.style.display = "";
      } else {
        button.style.display = "none";
      }
    });
  }
  
}