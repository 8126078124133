import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  showResourceColSettings(e) {
    const settingsPlaceholderEl = document.querySelector(e.target.dataset.selectorId);
    const url = settingsPlaceholderEl.dataset.url;

    fetch(url)
    .then((r) => r.text())
    .then((html) => { 
      settingsPlaceholderEl.innerHTML = html;
    })
    .then(() => {
        document.addEventListener('click', (e) => {
          if (!this.element.contains(e.target)) {
            if (settingsPlaceholderEl.firstElementChild) settingsPlaceholderEl.firstElementChild.remove();
          }
        })
    });
  }
}