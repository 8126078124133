import ApplicationController from "./application_controller";
import DOMPurify from "dompurify";
import { setDateValue } from "../utils/set_date_value";

export default class extends ApplicationController {
  static targets = ["badge_content", "container", "fieldRow", "newUserButton"];
  static values = {
    uuid: String,
    reflexName: String
  }

  addNewField(e) {
    e.preventDefault();
    const newFieldsContainer = document.querySelector("#multiselect-add-values-container");
    const existingValuesContainer = document.querySelector("#existing-values-container");
    const addSelectOptions = document.querySelector("#add-value-btn");
    const dataType = e.target.dataset.type;
    const value = e.target.value;
    let addRemoveBtn, userAction, required, moduleType;
    
    if (e.target.dataset.type === "undefined" || e.target.dataset.type === null) {
      addRemoveBtn = true;
      userAction = 'update'
    } else {
      addRemoveBtn =  newFieldsContainer.children.length > 1;
      userAction = 'create';
    }

    required = e.target.dataset.required
    moduleType = e.target.dataset.moduleType

    if (value == "singleselect" || value == "multiselect" || dataType == "multiselect-update") {
      fetch(`/admins/new_data_manager_field?add_remove_btn=${addRemoveBtn}&user_action=${userAction}&required=${required}&module_type=${moduleType}`, { redirect: "error" })
        .then((r) => r.text())
        .then((html) => {
          addSelectOptions?.classList.add("vanish");
          existingValuesContainer?.classList.remove("vanish");
          newFieldsContainer.innerHTML = html;
          newFieldsContainer.querySelector(".type-of-select").textContent = value == "singleselect" ? "Single" : "Multiple";
        }).finally(() => {
          if (dataType == "multiselect-update") this.element?.querySelector(".type-of-select").remove();
        });
    } else {
      addSelectOptions?.classList.add("vanish");
      existingValuesContainer?.classList.add("vanish");
      newFieldsContainer.innerHTML = "";
    }
    if (dataType == "multiselect-update") e.currentTarget.remove(); 
  }

  addField(e) {
    const target = e.target;
    this.addParentFlipAnimationAndBlurScrollView(e, target, target.dataset.fields)
  }

  addParentFlipAnimationAndBlurScrollView(e, target, dataFields, classes = "", twoParents = false, position = "beforebegin") {
    e.preventDefault();
    const parentContainer = twoParents ? target.parentElement.parentElement : target.parentElement;
    parentContainer.insertAdjacentHTML(
      position,
      DOMPurify.sanitize(
        `<div class="animate animate-flip-in-x ${classes}">
          ${dataFields}
        </div>`
      )
    );
    target.blur();
    target.scrollIntoView({behavior: "smooth", block: "center"});
  }

  removeField(e) {
    e.preventDefault();

    let fieldRow;
    if (e.target.dataset.type === "undefined" || e.target.dataset.type === null){
      fieldRow = this.fieldRowTarget;
    } else {
      fieldRow = e.target.parentElement;
    }

    fieldRow.classList.add("animate-flip-out-x");
    setTimeout(() => {
      fieldRow.remove();
    }, 500);
  }

  show(e) {
    e.preventDefault();
    const action = e.target.getAttribute("data-forms-action");
    const sortContainer = document.querySelector(
      `#transactions-${action}-container`
    );

    sortContainer.classList.remove("vanish");
    expandModule(sortContainer);
  }

  hide(e) {
    if (this.element.contains(e.target) == false) {
      this.containerTarget.classList.add("vanish");
    }
  }

  onSubmitSuccess() {
    closeModal();
    this.stimulate(`${this.reflexNameValue}#morph_view`, this.uuidValue);
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail;
  }

  setData(e) {
    const dateField = document.querySelector(e.target.getAttribute('data-forms-selector-name-value'));
    const contingencyType = e.target.getAttribute('data-contingency-type');
    const dateType = document.querySelector(`#${contingencyType}_date_type`);
    const daysNumInput = document.querySelector(`#trx_additional_info_${contingencyType}_days`);

    setDateValue(dateType, daysNumInput, dateField);
  }

  nonNegativeInteger(e) {
    if (e.target.value < 0) { e.target.value = 0 }
  }

  clear() {
    this.element.reset()
  }
}

function expandModule(module) {
  module.classList.add("slide-down");
}
