import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['input'];

  clearInputValue(e) {
    e.preventDefault();  
    this.inputTarget.value = ''; 
  }
}
