import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { existingResourceCount: Number }

  enableModalSubmitButton (e) {
    const btnContainer = document.querySelector(`${e.target.dataset.selectorId}-btn`);
    const btn = btnContainer.querySelector("button") || btnContainer.querySelector("a")

    if (Number(this.element.value) == this.existingResourceCountValue) {
      btn.classList.remove("disabled");
    } else {
      btn.classList.add("disabled");
    }
  }
}