import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { showModalWarning: Boolean }
  showModalWarningValueChanged() {
    if (this.showModalWarningValue) {
      window.onbeforeunload = warnOnUnsavedChanges;
      this.element.dataset.changedValue = "true";
      this.element.querySelectorAll(".tab").forEach(tab => {
        tab.dataset.action = "click->modal--confirm-modal--confirm-modal#renderModal";
        tab.dataset.controller = "modal--confirm-modal--confirm-modal";
        tab.dataset.href = tab.href;
        tab.href = "javascript:";
        tab.dataset["modal-ConfirmModal-ConfirmModalSelectorIdValue"] = "modal-component-tab-navigate-away-warning"
        tab.dataset["modal-ConfirmModal-ConfirmModalIsModalTriggerValue"] = "true"
      });
      return;
    } 
    if (this.element.dataset.changedValue == "true") {
        this.element.querySelectorAll(".tab").forEach(tab => {
            tab.dataset.action = "click->tabs--tabs#switchTab";
          tab.href = tab.dataset.href;
        });
        window.onbeforeunload = null;
      }
  }
}

function warnOnUnsavedChanges(event) {
  event.returnValue = 'You have unsaved changes! Are you sure you want to leave?';
  return 'You have unsaved changes! Are you sure you want to leave?';
}