import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "body", "toggleBtn", "btnContainer" ]
  static values = { isExpanded: Boolean, bodyHeight: Number }

  connect() {
    super.connect();
    setTimeout(() => {
      if (this.bodyTarget.scrollHeight > this.bodyHeightValue) super.removeVanish(this.btnContainerTarget);
    }, 500);
  }

  toggleExpand() {
    this.isExpandedValue = !this.isExpandedValue;
    if (this.isExpandedValue) {
      this.bodyTarget.classList.remove("clipped");
      this.toggleBtnTarget.textContent = "See less"
    } else {
      this.bodyTarget.classList.add("clipped");
      this.toggleBtnTarget.textContent = "See more..."
    }
  }
}