import ApplicationController from "./application_controller";

export default class extends ApplicationController {
static targets = [ "showEmojisBtn" ]

  showEmojis() {
    const trixToolbar = document.querySelector("trix-toolbar");
    const trix = document.querySelector("trix-editor");
    const container = document.createElement("div");
    container.classList.add("emojis-container");
    container.id = "emojis-container";
    const emojis = {
      "wink-tongue": "<span role='img' aria-label='wink and tongue emoji'>😜</span>",
      "smiling": "<span role='img' aria-label='smiling emoji'>😀</span>",
      "tears-joy": "<span role='img' aria-label='tears of joy emoji'>😂</span>",
      "frowning": "<span role='img' aria-label='frowning emoji'>🙁</span>",
      "thumbs-up": "<span role='img' aria-label='thumbs up emoji'>👍</span>",
      "thumbs-down": "<span role='img' aria-label='thumbs down emoji'>👎</span>",
      "point-up": "<span role='img' aria-label='Point up emoji'>☝️</span>",
      "raised-hands": "<span role='img' aria-label='Raised hands emoji'>🙌</span>",
    };
    this.showEmojisBar(trixToolbar, container);
    this.listenForEmojis(trix, emojis);
    this.showEmojisBtnTarget.classList.add('animate-zoom-out');
    setTimeout(() => {
      super.addVanish(this.showEmojisBtnTarget);
    }, 300);
  }

  showEmojisBar(trixToolbar, container) {
    trixToolbar.insertAdjacentElement('beforeend', container);
    container.innerHTML = '<button class="insert-emoji-btn" type="button" title="Insert Wink Tongue Emoji" data-trix-action="x-wink-tongue">😜</button><button class="insert-emoji-btn" type="button" title="Insert Grinning Emoji" data-trix-action="x-smiling">😀</button><button class="insert-emoji-btn" type="button" title="Insert Tears of Joy Emoji" data-trix-action="x-tears-joy">😂</button><button class="insert-emoji-btn" type="button" title="Insert Frowning Emoji" data-trix-action="x-frowning">🙁</button><button class="insert-emoji-btn" type="button" title="Insert Thumbs Up Emoji" data-trix-action="x-thumbs-up">👍</button><button class="insert-emoji-btn" type="button" title="Insert Thumbs Down Emoji" data-trix-action="x-thumbs-down">👎</button><button class="insert-emoji-btn" type="button" title="Insert Point Up Emoji" data-trix-action="x-point-up">☝️</button><button class="insert-emoji-btn" type="button" title="Insert Raised Hands Emoji" data-trix-action="x-raised-hands">🙌</button>';
  }

  listenForEmojis(trix, emojis) {      
    document.addEventListener("trix-action-invoke", function(event) {
      let emojiName = event.actionName.substring(2)    
      trix.editor.insertHTML(emojis[emojiName])
    })
  }
}