import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "checkbox" ]
  static values = {
    default: String
  }

  clearChoices(e) {
    const domainBtn = e.currentTarget;
    if (domainBtn.checked) {
      this.stimulate("DomainsReflex#update_domain_choice", domainBtn.getAttribute("data-default-domain-value"))
      domainBtn.checked = true;
    } else {
      this.stimulate("DomainsReflex#update_domain_choice", "")
      domainBtn.checked = false;
      return;
    }
    this.checkboxTargets.forEach(element => {
      if (element !== domainBtn) {
        element.checked = false; 
        domainBtn.checked = true; 
      } 
    });
  }
}