import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  addFilter(e) {
    const url = document.querySelector("#additional-filters-settings-placeholder").getAttribute("data-url")
    const urlParams = new URLSearchParams(url);
    const moduleType = urlParams.get('/additional_filters?module_type')
    const savedFilters = urlParams.get('saved_filters_names')
    const savedFiltersNames = savedFilters.split(",")
    const filterId = e.target.dataset.attrName
    let names;

    if (e.target.checked) {
      savedFiltersNames.push(filterId);
      names = savedFiltersNames;
    } else {
      names = savedFiltersNames.filter(item => item !== filterId);
    }

    document.querySelector("#additional-filters-settings-placeholder")
    .setAttribute("data-url", `/additional_filters?module_type=${moduleType}&saved_filters_names=${names.join(",")}`);
    
    this.stimulate("FiltersReflex#add_filter");
  }

  submitForm(e) {
    window.onbeforeunload = null; // Remove any unload warnings
    if ( e.keyCode == 13) { return false; }

    e.preventDefault();
    const form = document.querySelector(e.target.dataset.formId);

    if (form) {
      fetch(form.action, {
        method: 'POST',
        body: new FormData(form)
      })
    }
  }

  preventFormSubmit(e) {
    e.preventDefault();
  }
}