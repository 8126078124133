
export function setDateValue(dateType, daysNumInput, dateField, showDangerToast) {
  if (dateType.value) {
    const baseDate = document.querySelector(`#trx_additional_info_${dateType.value}`);
    if (baseDate.value) {
      const baseDateType = baseDate.id.split("trx_additional_info_")[1]; // eg due_diligence_date or offer_date
      const daysNum = daysNumInput.value ? daysNumInput.value : 0
      let date = new Date(baseDate.value + "T00:00:00Z");
      date.setDate(
        baseDateType === 'closing_date' ? date.getDate() - parseInt(daysNum) : date.getDate() + parseInt(daysNum)
      );
      dateField.value = date.toISOString().split('T')[0];
    } else {
      // show a error toast
    }
  } else {
    // show a error toast
  }
}
