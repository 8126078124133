import ApplicationController from "./application_controller";

export default class extends ApplicationController {  
  connect() {
    this.fetchChatMembers(); 
  }

  fetchChatMembers() {
    fetch(`/channels/${this.data.get("sgid")}/users/list.json?module_type=${this.data.get("module-type")}`)
      .then(response => response.json())
      .then(response => this._renderElements(response))
      .catch(error => callback([]))
  }

  _renderElements(response) {
    document.querySelector('#view-chat-members-button-wrapper').innerHTML = response.button_html;
    document.querySelector('#view-chat-members-list-wrapper').innerHTML = response.list_html;
  }
}