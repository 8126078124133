import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  addToken(e) {
    e.stopPropagation();
    e.preventDefault();
    const checklistGuid = this.element.href.split('checklistGuid=').pop();
    const fileGuid = this.element.dataset.fileGuid;

    fetch(`/add_token?checklistGuid=${checklistGuid}&fileGuid=${fileGuid}`)
    .then(response => response.json())
    .then(response => {
      window.open(response.url, '_blank', 'noopener');
    })
  }
}
