import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["counter", "bell", "button", "notifications"];

  connect() {
    this.updateNotificationsCounter();

    this.listenForNotificationCounterUpdates();
  }

  listenForNotificationCounterUpdates() {
    document.addEventListener('updateNotificationCounter', () => {
      this.updateNotificationsCounter()
    });
  }

  updateNotificationsCounter() {
    this.fetchNotificationsCounter(this.buttonTarget);

    this.refreshTimer = setInterval(() => {
      this.fetchNotificationsCounter(this.buttonTarget);
    }, 20000);
  }

  disconnect() {
    this.stopRefreshing();
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  show(e) {
    const moduleType = this.buttonTarget.dataset.moduleType;

    fetch(`/notifications?module_type=${moduleType}`)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);
        this.element.appendChild(fragment);
      });
  }

  toggle(e) {
    e.preventDefault();
    if (this.hasNotificationsTarget) {
      this.close(e);
    } else {
      this.show();
    }
  }

  close(event) {
    event.preventDefault();
    this.notificationsTarget.classList.remove("animate-zoom-in");
    this.notificationsTarget.classList.add("animate-zoom-out");
    setTimeout(() => {
      if (this.hasNotificationsTarget) this.notificationsTarget?.remove();
    }, 400);
  }

  fetchNotificationsCounter(element) {
    const moduleType = element.dataset.moduleType;
    fetch(`/notifications_counter?module_type=${moduleType}`).then((response) => {
      response.json().then((data) => {
        if (this.hasCounterTarget && data["count"] > 0) {
          this.counterTarget.classList.remove("hidden")
          if (data["count"] > 99) {
            this.counterTarget.textContent = "99+";
            this.counterTarget.classList.add("over-99");
          } else {
            this.counterTarget.textContent = data["count"];
            this.counterTarget.classList.remove("over-99");
          }
          this.counterTarget.classList.remove("hidden");
          if (this.bellTarget.classList.contains('animate-slow-pulse')) return;
          this.bellTarget.classList.add('animate-slow-pulse');
        } else {
          this.counterTarget.classList.add("hidden");
          if (this.bellTarget.classList.contains('animate-slow-pulse')) this.bellTarget.classList.remove('animate-slow-pulse');
        }
      });
    });
  }
}
