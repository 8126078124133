import { toggleHTMLError } from "./toggle_html_error";

export function toggleValidity(isValid, submitBtn, el, errorMsg, id, inputType) {
  if (isValid) {
    submitBtn.removeAttribute("disabled");
  } else {
    submitBtn.setAttribute("disabled", true);
  }
  toggleHTMLError(isValid, el, errorMsg, id, inputType);
}
