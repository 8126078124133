import ApplicationController from "./application_controller";
import { toggleSelectedPanel, updateContentHeight, removeSelectedCard } from "./selected_panel_controller";
import DOMPurify from "dompurify";

//? Handles reflex for individual task when clicked on in Tasks Panel to expand view and details of task
export default class extends ApplicationController {
  static targets = [
    "taskCardEl"
  ];

  static values = { 
    taskId: String,
    selectedTaskUuid: String,
    navbarSelectedTaskUuid: String
  };

  initialize() {
    this.selectedTaskPanel = document.getElementById("selected-panel");

    document.addEventListener('tasksFinishedLoading', () => {
      this.myPrioritiesSelectedTask();
    });
  }

  myPrioritiesSelectedTask() {
    if (this.navbarSelectedTaskUuidValue) {
      let selectedTaskCardEl = document.querySelector(`.task-card[data-task-uuid-value="${this.navbarSelectedTaskUuidValue}"]`);
      if (selectedTaskCardEl === null) {
        setTimeout(() => {
          this.myPrioritiesSelectedTask();
        }, 250);
      } else {
        this.selectTask(selectedTaskCardEl);
      }
    }
  }

  selectTask(eventOrEl) {
    this.selectedTaskCardEl = eventOrEl?.currentTarget ?? eventOrEl;
    if (this.selectedTaskCardEl == null) return;
    let delay = 0; // Delay for reflex so animations complete before updating data
    let firstTimeSelected = this.selectedTaskUuidValue == "";
    const isAlreadySelected = this.selectedTaskUuidValue == this.selectedTaskCardEl.dataset.taskUuidValue;

    // Updated which task is selected
    this.selectedTaskUuidValue = this.selectedTaskCardEl.dataset.taskUuidValue;
    
    // Update data-task-status on task panel to task's status to properly color values
    this.selectedTaskPanel.dataset.taskStatus = this.selectedTaskCardEl.dataset.taskStatusValue;


    // Close selected task panel if double clicked on same task card
    if (isAlreadySelected && !firstTimeSelected) {  
      this.selectedTaskCardEl.classList.toggle("is-selected");
      toggleSelectedPanel();
      return;
    }
    
    // When selecting another task when panel is already open so transition with fade
    if (!firstTimeSelected && this.selectedTaskPanel.dataset.isOpen == "true") {
      this.selectedTaskPanel.querySelectorAll(".selected-panel--container > *").forEach(element => {
        element.classList.add("animate-fade-out");
      });
      delay = 250;
    }
    
    // Since first time since page reload a selection was made, have to show selected task panel
    if (firstTimeSelected && this.selectedTaskPanel.dataset.isOpen == "false") toggleSelectedPanel();

    if (this.selectedTaskPanel.dataset.isOpen == "false") {
      toggleSelectedPanel();
    }
    
    removeSelectedCard();
    this.selectedTaskCardEl.classList.add("is-selected");    

    fetch(`/select_task?list_item_uuid=${this.selectedTaskUuidValue}`)
      .then(response => response.json())
      .then(data => {
        let taskContainer = document.querySelector("#selected-panel .selected-panel--container");
        taskContainer.innerHTML = DOMPurify.sanitize(data.html, { ADD_ATTR: ['target'] });
        
        if (delay !== 0) {
          setTimeout(() => {
            updateContentHeight(this.selectedTaskPanel);
          }, delay);
        }
      })
      .catch(error => console.log(error))
  }
  
}