import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    //? A hack that allows note form to detect validity for Trix input as required
    const hiddenInputNoteMsg = document.querySelector("#note_body_trix_input_note");
    if (hiddenInputNoteMsg) {
      hiddenInputNoteMsg.removeAttribute("type");
      hiddenInputNoteMsg.setAttribute("required", true);
      hiddenInputNoteMsg.classList.add("sr-only");
      hiddenInputNoteMsg.style.margin = "3rem -1rem -1rem";
    }
  }
}
