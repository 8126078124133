import { enableSubmitBtn } from "./enable_submit_btn";
import { toggleValidity } from "./toggle_validity";
import { errorMsg } from "./error_msg";

export function checkTcState(stateInput, email, type) {
  const submitBtn = document.querySelector("#create_transaction");
  const state = stateInput.value;
  let isValid = state !== "";
  let err;

  if (!["User::Tc", "User::JuniorTc"].includes(type)) {
    commonActions(submitBtn, true, stateInput, err);
    return true;
  }

  if (!isValid) {
    err = errorMsg("date-error", "You must select the State first");
    commonActions(submitBtn, isValid, stateInput, err)
    return isValid;
  }

  if (email) {
    const url = `${window.location.origin}/users/tc_state_validator?email=${email}&state=${state}&type=${type}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        isValid = data['isValid'];
        if (!isValid) {
          err = errorMsg("date-error", data['error']);
          commonActions(submitBtn, isValid, stateInput, err);
        }
      });
  }

  commonActions(submitBtn, isValid, stateInput, err);
  return isValid;
}

function commonActions(submitBtn, isValid, stateInput, err) {
  enableSubmitBtn(submitBtn, "#date-error");
  toggleValidity(isValid, submitBtn, stateInput, err, "#date-error", "select");
}