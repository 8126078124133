import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = [
    "removeAllBtn"
  ]
  // Allows Create List on Todolists/new to be enabled after Add Task is clicked
  enableCreateListBtn() {
    const createButton = document.querySelector('#create-task');
    const updateBtn = document.querySelector('#to-do-list-update-button');
    createButton?.removeAttribute('disabled');
    updateBtn?.classList?.remove("vanish");
  }

  confirmBatchRemove(e) {
    e.preventDefault();
    const tasksElColl = document.querySelectorAll(".to-do-task-checkbox");
    document.querySelector("#select-all-tasks-checkbox").checked = false;
    const tasksIds = this.checkedTasksCheckboxes(tasksElColl).map(el => el.getAttribute("data-list-item-id"));
    this.stimulate("TaskReflex#batch_remove_task", tasksIds).then(() => {
      return this.enableBulkDeleteIconBtn(0);
    });
  }

  confirmDefaultBatchRemove(e) {
    e.preventDefault();
    const tasksElColl = document.querySelectorAll(".to-do-task-checkbox");
    document.querySelector("#default-select-all-tasks-checkbox").checked = false;
    const tasksIds = this.checkedTasksCheckboxes(tasksElColl).map(el => el.getAttribute("data-list-item-id"));
    this.stimulate("TaskReflex#batch_remove_default_task", tasksIds).then(() => {
      return this.enableBulkDeleteIconBtn(0);
    });
  }

  toggleSelectAllTask(e) {
    const tasksElColl = document.querySelectorAll(".to-do-task-checkbox");
    const defaultTasks = e.target.getAttribute("data-default-task");
    
    tasksElColl.forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });
    
    this.stimulate("TaskReflex#show_delete_footer_confirmation_msg", this.checkedTasksCheckboxes(tasksElColl).length, defaultTasks);
    
    this.enableBulkDeleteIconBtn(0)
  }

  toggleSelectedTask(e) {
    const tasksElColl = document.querySelectorAll(".to-do-task-checkbox");
    const defaultTasks = e.target.getAttribute("data-default-task");
    this.stimulate("TaskReflex#show_delete_footer_confirmation_msg", this.checkedTasksCheckboxes(tasksElColl).length, defaultTasks);
    
    this.enableBulkDeleteIconBtn(1)
  }

  enableBulkDeleteIconBtn(selectNumber) {
    if(selectNumber > 0){
      const taskCheckboxes = document.querySelectorAll(".to-do-task-checkbox");
    
      if (this.checkedTasksCheckboxes(taskCheckboxes).length > selectNumber) {
        this.removeAllBtnTarget.classList.remove("pointer-events-none", "disabled");
        this.removeAllBtnTarget.classList.add("text-danger");
        return;
      }

      this.removeAllBtnTarget.classList.add("pointer-events-none", "disabled");
      this.removeAllBtnTarget.classList.remove("text-danger");
    }
  }

  checkedTasksCheckboxes(tasksElColl) {
    return Array.from(tasksElColl).filter(el => el.checked)
  }
  
  removeFooterConfirmation() {
    this.element.classList.remove("animate-bounce-in-up");
    this.element.classList.add("animate-bounce-out-down");

    ["#default-select-all-tasks-checkbox", "#select-all-tasks-checkbox"].forEach(selector => {
      let el = document.querySelector(selector)
      if (el) el.checked = false; 
    })
    const tasksElColl = document.querySelectorAll(".to-do-task-checkbox");
    tasksElColl.forEach(checkbox => {
      checkbox.checked = false;
    });

    setTimeout(() => {
      this.element.remove();
    }, 500)
  }
}
