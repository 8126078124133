import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  show() {
    const modal = document.querySelector(".image-viewer");
    modal.style.display = "block";
    window.onclick = function (e) {
      if (e.target == modal) {
        modal.style.display = "none";
      }
    };
  }
}
