import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  dismissResource(e) {
    e.preventDefault();

    const unassignedCountEl = document.querySelector("#index-unassigned-resources-counter-span");
    const strUnassignedCount = unassignedCountEl.innerText.replace("(", "").replace(")", "");
    const intUnassignedCount = parseInt(strUnassignedCount, 10)
    unassignedCountEl.innerText = `(${intUnassignedCount - 1})`;
    this.stimulate('ResourcesAssignReflex#dismiss_resource');
  }

  saveFilters(e) {
    window.onbeforeunload = null; // Remove any unload warnings
    if ( e.keyCode == 13) { return false; }

    e.preventDefault();
    const form = document.querySelector(e.target.dataset.formId);
    const moduleType = e.target.dataset.moduleType;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    if (form) {
      fetch(`/filter_setting?filter_for=${moduleType}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken
        },
        body: new FormData(form)
      })
    }

  }
}
