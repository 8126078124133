import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "filtersComponent", "filtersBtn" ]
  static values = {localStorage: String}

  initialize() {
    if (this.hasFiltersComponentTarget) {
      if (window.localStorage.getItem(`${this.localStorageValue}`) == 'true') {
        super.removeVanish(this.filtersComponentTarget);
        this.filtersBtnTarget.classList.remove("inverted");  
        return;
      }
      super.addVanish(this.filtersComponentTarget); 
      this.filtersBtnTarget.classList.add("inverted");  
    }
  }

  toggleFiltersVisibility() {
    this.filtersComponentTarget.classList.add("in-use");
    this.filtersComponentTarget.classList.toggle("vanish");
    this.filtersBtnTarget.classList.toggle("inverted");
    window.localStorage.setItem(`${this.localStorageValue}`, !this.filtersComponentTarget.classList.contains("vanish"));
    setTimeout(() => {
      this.filtersBtnTarget.blur();
    }, 200)
  }
}