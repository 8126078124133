import { Controller } from "stimulus";
import { debounce } from "../utils/debounce";

export default class extends Controller {
  static targets = ["results", "resultsEmail", "contactNameResults", "query", "emailQuery", "contactNameQuery"];

  disconnect() {
    this.reset();
  }

  fetchResults = (e) => {
    let query = e.target.value;

    if (query === "") {
      this.reset();
      return;
    }

    if (query === this.previousQuery) {
      return;
    }

    this.previousQuery = query;
    const searchResource = e.target.getAttribute('data-search-resource');
    const searchFieldType = e.target.getAttribute('data-search-field-type');
    const randNum = e.target.getAttribute('data-search-rand-num');
    const type = e.target.getAttribute('data-search-vendor-type');
    const state = e.target.getAttribute('data-search-state');
    const url = `/search_${searchResource}?query=${query}&type=${type}&state=${state}`;

    if (query.length > 2) {
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          document.querySelector(`#${searchFieldType}-field-${randNum}`).innerHTML = html;
        });
    }
  };

  debouncedFetchResults(e) {
    debounce(this.fetchResults, 500)(e);
  }

  toggleRequiredAttr(e) {
    const prefix = e.target.getAttribute('data-search-vendor-type').toLowerCase();
    // Set the four fields that need to be modified with required
    const fieldTypes = ["officer-name", "company", "phone", "email", "address"];
    let inputArr = [];
    let abbrs = document.querySelectorAll(`.${prefix}-abbr`);
    
    // Grab the four escrow or attorney fields that are relevant
    fieldTypes.forEach((arr) => {
      let el = document.getElementById(`${prefix}-${arr}-field`)
      inputArr.push(el)
    });

    e.target.value.length > 0 ? abbrs.forEach((abbr) => abbr.classList.remove("vanish")) : abbrs.forEach((abbr) => abbr.classList.add("vanish"));
    // Make escrow and attorney fields required or not
    inputArr.map((input) => {
      if (e.target.value.length > 0) {
        input?.setAttribute("required", true);
      } else {
        input?.removeAttribute("required");
      }
    });
  }

  reset() {
    if (this.hasResultsTarget) {
      this.resultsTarget.innerHTML = "";
    }
    if (this.hasResultsEmailTarget) {
      this.resultsEmailTarget.innerHTML = "";
    }
    this.previousQuery = null;
  }

  // Clears dropdown search results when the email or name input is unfocused.
  clearResults(e) {
    const searchFieldType = e.target.getAttribute('data-search-field-type');
    const randNum = e.target.getAttribute('data-search-rand-num');
    setTimeout(() => {
      if (this.element.dataset.id) {
        document.querySelectorAll(`.search-field-container[data-id="${this.element.dataset.id}"]`).forEach((search) => search.innerHTML = "");
      }
      if (this.hasResultsTarget) {
        document.querySelector(`#${searchFieldType}-field-${randNum}`).innerHTML = "";
      }
      if (this.hasResultsEmailTarget) {
        this.resultsEmailTarget.innerHTML = "";
      }
      if (this.hasContactNameResultsTarget) {
        this.contactNameResultsTarget.innerHTML = "";
      }
    }, 350);
  }
}
