import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "toggleBtn", "tasksContainer" ]

  switchTaskStatusView(e) {
    const clickedBtn = e.currentTarget;
    this.toggleBtnTargets.forEach((btn) => {
      btn.classList.remove("is-selected");
    });
    this.tasksContainerTargets.forEach((container) => {
      container.classList.add("vanish");
    });
    clickedBtn.classList.add("is-selected");
    this.element.querySelector(`section[data-view='${clickedBtn.dataset.view}']`).classList.remove("vanish");
  }
}