import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

export default class extends ApplicationController {
  connect() {
    StimulusReflex.register(this);
  }
  
  toggleStateSelect(e) {
    const defaultAddTaskBtnContainer = document.querySelector("#default-task-template-add-btn");
    const defaultToDoTasksContainer = document.querySelector("#default-to-do-template-tasks-container");
    const defaultToDoTasksTable = document.querySelector("#default-to-do-template-table");
    const applyDefaultBtnContainer = document.querySelector("#apply-default-btn-container");
    const listAddTaskBtnContainer = document.querySelector("#new-task-btn-container");
    const taskListTableContainer = document.querySelector("#task-list-table-container");
    const toDoTemplateDataAttributeFields = document.querySelectorAll(".to-do-template-data-attribute-field");
    const secondaryAttributeField = document.querySelector(".secondary-attribute-field");

    if (e.target.value == "") {
      defaultAddTaskBtnContainer.classList.remove("vanish");
      listAddTaskBtnContainer?.classList?.add("vanish");
      taskListTableContainer?.remove();
      defaultToDoTasksTable?.classList?.remove("vanish");
      
      defaultToDoTasksContainer.classList.remove("vanish");
      applyDefaultBtnContainer.classList.add("vanish");
      if (secondaryAttributeField) {
        secondaryAttributeField.value = "";
        secondaryAttributeField.setAttribute("disabled", true);
      }

    } else {
      const dataAttrSelected = [];
      toDoTemplateDataAttributeFields.forEach(field => {
        if (field.value !== "") dataAttrSelected.push(field.value);
      });

      defaultAddTaskBtnContainer.classList.add("vanish");
      defaultToDoTasksTable?.classList?.add("vanish");

      const dataAttr = document.querySelectorAll(`#to_do_template_list_items__${e.target.dataset.attrType}`);
      dataAttr.forEach(el => el.value = e.target.value);

      switch (dataAttrSelected.length) {
        case 1:
          if (secondaryAttributeField) secondaryAttributeField.removeAttribute("disabled");
          if (toDoTemplateDataAttributeFields.length == 1) this.reflexActions(e, {primary_attr_value: e.target.value});
          break;
        case 2:
          const primaryAttrValue = document.querySelector(`[data-attr-type="primary_attr_value"]`).value;
          const secondaryAttrValue = document.querySelector(`[data-attr-type="secondary_attr_value"]`).value;

          const taskDataAttrs = {
            primary_attr_value: primaryAttrValue,
            secondary_attr_value: secondaryAttrValue
          }
          
          this.reflexActions(e, taskDataAttrs);
          break;
      }
    }
    
    this.deselectTasks();
  }

  reflexActions(e, taskDataAttrs) {
    const defaultTaskContainer = document.querySelector("#default-list-items-table-body");
    const applyDefaultBtnContainer = document.querySelector("#apply-default-btn-container");

    if (defaultTaskContainer.children.length > 0) {
      applyDefaultBtnContainer.classList.remove("vanish");
    } else {
      applyDefaultBtnContainer.classList.add("vanish");
    }
    const toDoTemplateSgid = e.target.dataset.toDoTemplateSgid;
    const moduleType = e.target.dataset.moduleType;
    this.stimulate("ToDoReflex#choose_to_do_template", toDoTemplateSgid, moduleType, taskDataAttrs);
  }

  deselectTasks() {
    const tasksElColl = document.querySelectorAll(".to-do-task-checkbox");
    tasksElColl.forEach(checkbox => checkbox.checked = false);
    document.querySelector("#footer-confirmation-container")?.remove();
    const allTaskCheckbox = document.querySelector("#select-all-tasks-checkbox");
    if (allTaskCheckbox) allTaskCheckbox.checked = false;
    const defaultAllTaskCheckbox = document.querySelector("#default-select-all-tasks-checkbox");
    if (defaultAllTaskCheckbox) defaultAllTaskCheckbox.checked = false;
  }

  applyTaskToAll(e) {
    e.preventDefault();
    const form = document.querySelector(e.target.dataset.formId);
    const currentAction = form.action;
    const url = new URL(currentAction);
    const searchParams = url.searchParams;

    searchParams.set("apply_to_all", "true");
    form.setAttribute("action", url.toString());
    
    const formData = new FormData(form);
    const xhr = new XMLHttpRequest();

    xhr.open('POST', form.action, true);

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken); 
    xhr.send(formData);
  }
}
