import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "myPriorities", "toggleBtn", "closeBtn"]

  renderFetchMyPriorities(e) {
    const myPrioritiesWindow = document.querySelector("#my-priorities-window--container");

    if (myPrioritiesWindow.classList.contains("vanish")) {
      this.toggleBtnTarget.classList.add("is-open");
      myPrioritiesWindow.classList.remove("vanish");
    } else {
      this.closeMyPrioritiesWindow();
    }
  }

  closeMyPrioritiesWindow() {
    const myPrioritiesWindow = document.querySelector("#my-priorities-window--container");
    this.toggleBtnTarget.classList.remove("is-open"); 
    this.myPrioritiesTarget.classList.add("animate-zoom-out");
    setTimeout(() => {
      myPrioritiesWindow.classList.add("vanish");

      this.myPrioritiesTarget.classList.remove("animate-zoom-out");
    }, 500);
  }

  updateMyPrioritiesCounters(e) {
    let status = e.currentTarget.querySelector("input").dataset.myPrioritiesStatus;
    let badgeCount = this.element.querySelector(`#tasks-col-component--count-badge--${status}--my-priorities`)
    badgeCount.textContent = parseInt(badgeCount.textContent - 1) 
    setTimeout(() => {
      // User completed all priorities
      if (this.element.querySelectorAll(".task-card").length === 0) {
        this.element.querySelector("svg.has-tasks").classList.remove("has-tasks");
        this.closeMyPrioritiesWindow();
        this.stimulate("ApplicationReflex#render_cable_flash", "Congrats! All My Priorities are completed!", "notice")
      }
    }, 1000);
  }
}