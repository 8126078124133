import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggleSelected(e) {
    const resourcesElColl = document.querySelectorAll(e.target.dataset.resourceElClass);
    const checkedCheckboxes = this.checkedTasksCheckboxes(resourcesElColl);
    const isSelectAllResouces = e.target.dataset.isSelectAllResources == 'true';
    const allResourcesCount = e.target.dataset.allResourcesCount || 0;
    let resourcesIds = []

    const reflexName = e.target.dataset.reflexClassName;
    const resourceType = e.target.dataset.resourceType;
    const moduleType = e.target.dataset.moduleType;

    checkedCheckboxes.forEach(el => {
      let resourceId = el.getAttribute("data-resource-id");
      resourcesIds.push(resourceId);
    })

    if (isSelectAllResouces) {
      this.stimulate(`${reflexName}#select_all_resources`, checkedCheckboxes.length, resourceType, Number(allResourcesCount), moduleType);
    } else { // Select visible resources
      this.stimulate(`${reflexName}#show_footer_confirmation_msg`, checkedCheckboxes.length, resourcesIds, resourceType, moduleType);
    }

    if (checkedCheckboxes.length === 0) {
      const selectAllFieldSelector = e.target.dataset.selectAllFieldSelector;
      let el = document.querySelector(selectAllFieldSelector);
      if (el) { el.checked = false; }
    }

    if (e.target.dataset.resourceElClass == ".resources-index-checkbox") {
      const unassignedAgentsAssignBtns = document.querySelectorAll(".unassigned-agent-card-assign-btn");
      if (checkedCheckboxes.length > 1) {
        unassignedAgentsAssignBtns.forEach(btn => {
          btn.classList.add("disabled");
        });
      } else {
        unassignedAgentsAssignBtns.forEach(btn => {
          btn.classList.remove("disabled");
        });
      }
    }
  }

  checkedTasksCheckboxes(tasksElColl) {
    return Array.from(tasksElColl).filter(el => el.checked)
  }

  removeFooterConfirmation(e) {
    this.element.classList.remove("animate-bounce-in-up");
    this.element.classList.add("animate-bounce-out-down");

    const selectAllFieldSelector = e.target.dataset.selectAllFieldSelector;
    const tasksElColl = document.querySelectorAll(e.target.dataset.resourceElClass);
    let el = document.querySelector(selectAllFieldSelector);

    if (el) { el.checked = false; }
    
    tasksElColl.forEach(checkbox => {
      checkbox.checked = false;
    });

    if (e.target.dataset.resourceElClass == ".resources-index-checkbox") {
      const unassignedAgentsAssignBtns = document.querySelectorAll(".unassigned-agent-card-assign-btn");
      unassignedAgentsAssignBtns.forEach(btn => {
        btn.classList.remove("disabled");
      });
    }

    this.removeFooter(e);
  }

  removeFooter(e) {
    setTimeout(() => {
      document.querySelector("#footer-confirmation-container")?.remove();
    }, 500)
  }

  toggleSelectAll(e) {
    document.querySelectorAll(e.target.dataset.resourceElClass).forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });

    this.toggleSelected(e);
  }
}