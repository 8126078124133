export function confirmation(dataset) {
  const actionType = dataset.actionType;
  const moduleType = dataset.moduleType;
  const templateId = dataset.templateId;
  const listItemId = dataset.listItemId;
  const btnSelector = dataset.btnSelector;
  const isIndexPage = dataset.isIndexPage;
  const initiateFromCommTemplate = dataset.initiateFromCommTemplate;

  fetch(`/action_confirmation?module_type=${moduleType}&template_id=${templateId}&action_type=${actionType}&list_item_id=${listItemId}&is_index_page=${isIndexPage}&initiate_from_comm_template=${initiateFromCommTemplate}`, { redirect: "error" })
    .then((r) => r.text())
    .then((html) => {
      document.querySelector(btnSelector).innerHTML = html;
    });
}